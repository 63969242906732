import { makeStyles } from "@material-ui/core/styles";

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e";
const COLOR_YELLOW = "#EDA247";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const TOP_BAR_HEIGHT = 56;

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    height: `calc(100vh - ${TOP_BAR_HEIGHT + 30}px)`,
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      marginLeft: '3%',
      marginRight: '3%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 30,
      marginRight: 30,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 45,
      marginRight: 45,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 80,
      marginRight: 80,
    }
  },
  noTeamsInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  messagesContainer: {
    overflowX: 'hidden',
    overflowY: 'visible',
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    flex: 1,
    boxSizing: 'border-box',
    maxHeight: 'calc(100vh)',
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    margin: theme.spacing(1)
  },
  messageBubble: {
    padding: theme.spacing(1),
    borderRadius: '8px',
    maxWidth: '70%',
    display: 'inline-block',
    wordBreak: 'break-word',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '75%'
    },
  },
  myMessage: {
    backgroundColor: COLOR_PRIMARY,
    color: '#fff',
    alignSelf: 'flex-end',
    marginLeft: 'auto',
  },
  otherMessage: {
    backgroundColor: '#e0e0e0',
    color: '#000',
    alignSelf: 'flex-start',
  },
  messageText: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  userPhoto: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(0.5),
    borderRadius: '50%',
    border: '1px solid'
  },
  inputContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  sendButton: {
    borderRadius: '50%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    backgroundColor: ZONE_BACKGROUND_COLOR,
    borderRadius: '50%',
    padding: 8,
    zIndex: 10,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
  },
  skeletonWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '10px'
  },
  noConversation: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  noConversationBtn: {
    marginTop: -60
  }
}));