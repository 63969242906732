import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import moment from 'moment';
import { formatTime, getTimeFormat, getDateFormat, handleDateInputChange } from '../utils/dates';
import './DatePicker.css'

const COLOR_LIGHT = "#f4f4f4";
const COLOR_PRIMARY = '#45d69f';
const LABEL_COLOR = '#101116';
const TEXTFIELD_COLOR = '#242834';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: TEXTFIELD_COLOR
  },
  root: {
    color: COLOR_LIGHT,
    fontSize: 16,
    "&$focusedLabel": {
      color: COLOR_PRIMARY
    },
    "&$erroredLabel": {
      color: "rgb(208,48,63)"
    }
  },
  inputRoot: {
    color: COLOR_LIGHT,
    borderRadius: 8,
    "&&&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderRadius: 8,
      borderBottom: `2px solid #45d69f`
    }
  },
  focusedLabel: { color: "#45d69f" },
  pickerRoot: {
    color: COLOR_LIGHT
  }
}));
    

const CDatePicker = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const dateFormat = getDateFormat(i18n.language);
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');
  const [previousValue, setPreviousValue] = useState(inputValue);


  useEffect(() => {
    if (props.defaultValue) {
      const formattedDate = moment(props.defaultValue, "YYYY-MM-DD").format(dateFormat.format);
      setInputValue(formattedDate);
    }
  }, [props.defaultValue, dateFormat.format]);

  const handleChange = (event) => {
    handleDateInputChange(event, dateFormat.format, (formattedValue) => {
      setInputValue(formattedValue);
  
      // Vérifiez si la longueur de la date est complète et si elle est valide
      if (formattedValue.length === 10 && moment(formattedValue, dateFormat.format, true).isValid()) {
        const newFormattedDate = moment(formattedValue, dateFormat.format).format("YYYY-MM-DD");
        if (props.inputCallback) {
          props.inputCallback(newFormattedDate);
        }
      } else {
        if (props.inputCallback) {
          props.inputCallback(null);
        }
      }
    });
  };
  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TextField
        label={props.label}
        value={inputValue}
        onChange={handleChange}
        variant="filled"
        size="small"
        className={[classes.input, props.className].join(' ')}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.root,
            focused: classes.focusedLabel,
            error: classes.erroredLabel
          }
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot
          }
        }}
        placeholder={dateFormat.label}
      />
    </LocalizationProvider>
  );
};

const CTimePicker = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const timeFormat = getTimeFormat(i18n.language);

  const is12HourFormat = timeFormat.includes('A') || timeFormat.includes('a');

  const [inputValue, setInputValue] = useState(
    props.defaultValue ? formatTime(new Date(props.defaultValue), timeFormat) : ''
  );
  const [previousValue, setPreviousValue] = useState(inputValue);

  const formatTimeInput = (value) => {
    if (!value) return '';

    const numbersOnly = value.replace(/[^0-9]/g, '');
    let formattedTime = '';

    if (is12HourFormat) {
      if (numbersOnly.length >= 2) {
        formattedTime = `${numbersOnly.slice(0, 2)}:${numbersOnly.slice(2, 4)}`;

        if (value.length === 2 && previousValue.length !== 1) {
          formattedTime = `${numbersOnly.slice(0, 2)}${numbersOnly.slice(2, 4)}`;
        }
        
        if (formattedTime.length === 5 && previousValue.length !== 8) {
          if (value[5] === 'A' || value[4] === 'a') {
            formattedTime += ' AM';
          } else {
            formattedTime += ' PM';
          }
        }

      } else {
        formattedTime = numbersOnly;
      }
    } else {
      if (numbersOnly.length >= 2) {
        formattedTime = `${numbersOnly.slice(0, 2)}:${numbersOnly.slice(2, 4)}`;
      } else {
        formattedTime = numbersOnly;
      }
    }

    return formattedTime;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    //const formattedValue = value.length >= inputValue.length ? formatTimeInput(value) : value;
    const formattedValue = formatTimeInput(value);

    setInputValue(formattedValue);
    setPreviousValue(formattedValue);

    // Appeler inputCallback uniquement si l'entrée n'est pas vide
    const momentTime = moment(formattedValue, timeFormat, true);
    props.inputCallback(momentTime);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TextField
        label={props.label}
        value={inputValue}
        onChange={handleInputChange}
        variant="filled"
        size="small"
        className={[classes.input, props.className].join(' ')}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.root,
            focused: classes.focusedLabel,
            error: classes.erroredLabel
          }
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot
          }
        }}
        placeholder={timeFormat}
      />
    </LocalizationProvider>
  );
};

export { CDatePicker, CTimePicker };