import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './reducers/user';
import invitationsReducer from './reducers/invitations';
import conversationsReducer from './reducers/conversations';
import documentsReducer from './reducers/documents';

const rootReducer = combineReducers({
  conversations: conversationsReducer,
  documents: documentsReducer,
  invitations: invitationsReducer,
  user: userReducer
});

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user']
};

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  })
});

export const persistor = persistStore(store);