import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import i18next from 'i18next';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as queries from '../../graphql/queries';
import CButton from "../../components/Button";
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import { useDispatch } from 'react-redux';
import { setPhotoPath } from '../../store/reducers/user';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import { getCurrentSeason } from "../../utils/season";
import ClubPhotoModal from '../../components/ProfilePicture/clubModal';
import Skeleton from '@mui/material/Skeleton';
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket.svg';
import { ReactComponent as InfinityIcon } from '../../assets/icons/infinity.svg';
import { ReactComponent as PremiumIcon } from '../../assets/icons/premium.svg';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_RED = "#fc3c2e";
const COLOR_BLUE = "#25a5e6";
const COLOR_PURPLE = "#9d44fd";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const ZONE_BACKGROUND_COLOR_2 = "#242834";
const BUTTON_WIDTH = 100;

const formatDate = (date, language) => {
  let formattedDate;
  switch (true) {
    case language === "fr":
      formattedDate = moment(date).format("DD-MM-YYYY");
      break;
    default:
      formattedDate = moment(date).format("MM-DD-YYYY");
      break;
  }
  return formattedDate;
};

const UnsubscribeModal = ({ modalOpenCallback, subscription }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      marginBottom: 30
    },
    selectPhoto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },
    btnZone: {
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    inlineBtn: {
      width: '100%'
    },
    profilePicture: {
      marginBottom: 30,
      width: 'auto',
      [theme.breakpoints.up('xs')]: {
        maxWidth: 150,
        height: 150
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 180,
        height: 180
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 200,
        height: 200
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 220,
        height: 220
      }
    }
  }))


  const { t } = useTranslation()
  const classes = useStyles()
  const userStored = useSelector((state) => state?.user)

  const cancel = async () => {
    modalOpenCallback({type:"", value: false})
  }

  const validate = async () => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/subscriptions/v1/subscriptions/${subscription.subscription_id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        }
      });
    
      const respStatus = await resp.status
      modalOpenCallback({type:"", value: false})
      if(respStatus === 200) {
        // Afficher un message comme quoi l'abonnement prendre fin le: date
      }
    }
    catch(err) { console.log(err) }
  }

  return (
    <div className={classes.container}>
      <CText level="h2" fontWeight={700} className={classes.title} color="primary" label={`${t('club_profile.modals.1.title')}`} />
      <div className={classes.btnZone}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CButton
              label={t('club_profile.modals.1.buttons.0.label')}
              className={classes.inlineBtn}
              onClick={() => cancel()}
              height={38}
              color={COLOR_PINK}
              type="contained"
            />
          </Grid>
          <Grid item xs={6}>
            <CButton
              label={t('club_profile.modals.1.buttons.1.label')}
              className={classes.inlineBtn}
              height={38}
              color={COLOR_PRIMARY}
              type="contained"
              loader={true}
              onClick={async () => await validate()}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const Profile = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      flexGrow: 1
    },
    team: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 30
    },
    infoTitle: {
      marginBottom: 30
    },
    card: {
      backgroundColor: ZONE_BACKGROUND_COLOR,
      padding: 20,
      borderRadius: 18,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('xs')]: {
        height: 150
      },
      [theme.breakpoints.up('sm')]: {
        height: 180
      }
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    cardContentTitle: {
      marginBottom: 10
    },
    tableRowMargin: {
      marginBottom: 8
    },
    tableDataCentered: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'center'
    },
    teamLogoZone: {
      textAlign: 'center',
      marginBottom: 15,
      [theme.breakpoints.up('xs')]: {
        width: 150,
        height: 150
      },
      [theme.breakpoints.up('sm')]: {
        width: 180,
        height: 180
      }
    },
    teamLogo: {
      height: '100%',
      width: 'auto',
      cursor: 'pointer',
      "&:hover": {
        opacity: '0.8'
      }
    },
    teamIcon: {
      fill: COLOR_LIGHT
    },
    planIcon: {
      width: 40,
      height: 40,
      marginRight: 10,
    },
    starterColor: {
      color: COLOR_PINK,
      fill: COLOR_PINK
    },
    premiumColor: {
      color: COLOR_PURPLE,
      fill: COLOR_PURPLE
    },
    ultimateColor: {
      color: COLOR_PRIMARY,
      fill: COLOR_PRIMARY
    },
  }));

  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const userStored = useSelector((state) => state?.user)

  const [club, setClub] = useState({})
  const [loading, setLoading] = useState(true);
  const [teamsCount, setTeamsCount] = useState(0);
  const [currentSeason, setCurrentSeason] = useState("");
  const [subscription, setSubscription] = useState({});

  // Get User stored
  const userPhotoStored = userStored.photo_path

  useEffect(() => {
    const fetchData = async () => {
      const clubAdmin = await getClubAdminInfo();
      const clubId = clubAdmin?.club_id;

      const currentSeason = await getCurrentSeason();
      setCurrentSeason(currentSeason)

      await getClubInfo(clubAdmin, clubId);
      await getTeamsCount(clubId);
      await getSubscription(clubId);
      setLoading(false);
    }
  
    fetchData();
  }, [userStored]);
  
  const getClubAdminInfo = async () => {
    try {
      const userIdStored = userStored.info.id;
      console.log(userIdStored)
      let clubAdmin = await API.graphql({ query: queries.getClubAdmin, variables: { id: userIdStored }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      clubAdmin = clubAdmin?.data?.getClubAdmin

      return clubAdmin
    }
    catch (err) { console.log(err) }
  }

  const getClubInfo = async (clubAdmin, clubId) => {
    try {
      let getClubResp = await API.graphql({ query: queries.getClub, variables: { id: clubId }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
      getClubResp = getClubResp?.data?.getClub
      dispatch(setPhotoPath({ "photoPath": getClubResp.logo }))
      setClub(getClubResp)
    }
    catch (err) { console.log(err) }
  }

  const getSubscription = async(clubId) => {
    try {
      const filter = {
        club_id: { eq: clubId },
        or: [
          {end_date: { ge: moment().format("YYYY-MM-DD")}},
          {end_date: {attributeExists: false}}
        ]
      }

      let subscriptionsList = await API.graphql({ query: queries.listAppSubscriptions, variables: { filter: filter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      subscriptionsList = subscriptionsList.data.listAppSubscriptions.items;
      subscriptionsList = subscriptionsList.sort((a, b) => moment(b.end_date).valueOf() - moment(a.end_date).valueOf());
      
      let currentSubscription = subscriptionsList[0]
      if(currentSubscription.end_date ) {
        currentSubscription.end_date = formatDate(currentSubscription.end_date, i18next.language)
      }
      setSubscription(currentSubscription);    
    }
    catch(err) { console.log(err) }
  }

  const getTeamsCount = async(clubId) => {
    try {
      const filter = {
        club_id: { eq: clubId },
        deleted: { eq: false }
      }

      let teamsList = await API.graphql({ query: queries.listClubTeams, variables: { filter: filter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      teamsList = teamsList.data.listTeams.items;

      setTeamsCount(teamsList.length);      
    }
    catch(err) { console.log(err) }
  }

  // Handle Add profile photo
  const [modalOpen, setModalOpen] = useState({type: "", value: false})

  let PLAN_ICON;
  let PLAN_COLOR;
  switch (subscription?.plan?.id) {
    case '001':
      PLAN_ICON = <RocketIcon className={classes.planIcon} />
      PLAN_COLOR = COLOR_PINK
      break
    case '002':
      PLAN_ICON = <PremiumIcon className={classes.planIcon} />
      PLAN_COLOR = COLOR_PURPLE
      break
    case '003':
      PLAN_ICON = <InfinityIcon className={classes.planIcon} />
      PLAN_COLOR = COLOR_PRIMARY
      break
  }

  // Handle Unsubscribe
  const unsubscribe = () => {
    setModalOpen({type: "unsubscribe", value: true})
  }

  //Handle Modal Content
  let MODAL_CONTENT;

  if(modalOpen.type === "photo") {
    MODAL_CONTENT = <ClubPhotoModal modalOpenCallback={(data) => setModalOpen(data)} />
  } else if(modalOpen.type === "unsubscribe") {
    MODAL_CONTENT = <UnsubscribeModal modalOpenCallback={(data) => setModalOpen(data)} subscription={subscription} />
  }

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.team}>
          <div className={classes.teamLogoZone}>
            <ClubPhoto
              className={[classes.teamLogo].join(' ')}
              onClick={() => setModalOpen({type: "photo", value: true})}
              owner={true}
              loading={loading}
            />
          </div>
          <CText
            level="h2"
            color="light"
            loading={loading}
          >
            {club?.names?.[0] ? JSON.parse(club.names[0])?.name : null}
          </CText>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Grid container spacing={1} style={{ maxWidth: 650 }}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.card}>
                <CText level="h3" color="light" fontWeight={700} loading={loading}>
                  {t('club_profile.contents.0.title')}
                </CText>
                <div className={classes.cardContent}>
                  <CText
                    level="h3"
                    color="light"
                    fontWeight={700}
                    className={classes.cardContentTitle}
                    loading={loading}
                  >
                    {teamsCount}
                  </CText>
                  <CText level="p3" color="light" fontWeight={700} loading={loading}>
                    {t('club_profile.contents.0.labels.0')} {currentSeason}
                  </CText>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.card}>
                <CText level="h3" color="light" fontWeight={700} loading={loading}>
                  {t('club_profile.contents.1.title')}
                </CText>
                <div className={classes.cardContent}>
                  <CText
                    level="h3"
                    fontWeight={700}
                    className={[
                      subscription?.plan?.id === '001' ? classes.starterColor : null,
                      subscription?.plan?.id === '002' ? classes.premiumColor : null,
                      subscription?.plan?.id === '003' ? classes.ultimateColor : null,
                      classes.cardContentTitle
                    ].join(' ')}
                    loading={loading}
                  >
                    {PLAN_ICON}
                    {subscription?.plan?.name}
                  </CText>

                  {loading ?
                    <Skeleton variant="rounded" width={120} height={22} />
                    :
                    subscription?.end_date ? (
                      <CText level="p3" color="light" fontWeight={700}>
                        {t('club_profile.contents.1.labels.0')} : {subscription.end_date}
                      </CText>
                    ) : (
                      <CButton
                        label={t('club_profile.buttons.1.label')}
                        onClick={() => unsubscribe()}
                        size={14}
                        height={28}
                        color={COLOR_PINK}
                        type="contained"
                      />
                    )
                  }
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <CModal
          open={modalOpen.value}
          onClose={() => setModalOpen({type: "", value: false })}
          content={MODAL_CONTENT}
        >
        </CModal>
      </div>
    </div>
  )
}

export { Profile }