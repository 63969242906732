import { createSlice } from '@reduxjs/toolkit';

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: {
    conversations: []
  },
  reducers: {
    addConversation: (state, action) => {
      const { id } = action.payload;
      const existingConversation = state.conversations.find(conv => conv.id === id);

      if (!existingConversation) {
        state.conversations.push({
          id: id,
          count: 0,
          messages: []
        });
      } else {
        //console.warn(`Conversation avec l'ID ${id} existe déjà.`);
      }
    },
    incrementConversationCount: (state, action) => {
      const { id } = action.payload;
      const existingConversation = state.conversations.find(convo => convo.id === id);
      if (existingConversation) {
        existingConversation.count += 1;
      } else {
        state.conversations.push({ id: id, count: 1, messages: [] });
      }
    },
    updateConversationCount: (state, action) => {
      const { id, count } = action.payload;

      const existingConversation = state.conversations.find(convo => convo.id === id);

      if (existingConversation) {
        existingConversation.count = count;
      } else {
        state.conversations.push({ id: id, count, messages: [] });
      }
    },
    resetConversationCount: (state, action) => {
      const { id } = action.payload;
      const existingConversation = state.conversations.find(convo => convo.id === id);
      if (existingConversation) {
        existingConversation.count = 0;
      } else {
        state.conversations.push({ id: id, count: 0, messages: [] });
      }
    },
    addMessageToConversation: (state, action) => {
      const { id, message, prepend } = action.payload;

      const existingConversation = state.conversations.find(convo => convo.id === id);

      if (existingConversation) {
        const messageExists = existingConversation.messages.some(
          (msg) => msg.id === message.id
        );
    
        if (!messageExists) {
          if (prepend) {
            existingConversation.messages.unshift(message);
          } else {
            existingConversation.messages.push(message);
          }
        }
      } else {
        state.conversations.push({ id: id, count: 0, messages: [message] });
      }
    },    
    updateMessageIdInConversation: (state, action) => {
      const { id, tempId, newId } = action.payload;
      const existingConversation = state.conversations.find(convo => convo.id === id);
    
      if (existingConversation) {
        const messageToUpdate = existingConversation.messages.find(msg => msg.id === tempId);
        if (messageToUpdate) {
          messageToUpdate.id = newId;
        }
      }
    }
  }
});

export const {
  addConversation,
  incrementConversationCount, 
  resetConversationCount, 
  updateConversationCount,
  addMessageToConversation,
  updateMessageIdInConversation
} = conversationsSlice.actions;

export default conversationsSlice.reducer;