export const onCreateConversation = `subscription onCreateConversation($team_id: ID!, $season: String!) {
  onCreateConversation(team_id: $team_id, season: $season) {
    id
    team_id
    season
  }
}`;

export const onCreateMessage = `subscription onCreateMessage($filter: CustomModelSubscriptionMessageFilterInput) {
  onCreateMessage(filter: $filter) {
    id
    conversation_id
    sender {
      id
      first_name
      last_name
      photo_path
    }
    type
    content
    createdAt
    read_by
  }
}`;

export const onCreateTeamUser = `subscription onCreateTeamUser($user_id: ID!) {
  onCreateTeamUser(user_id: $user_id) {
    id
    user_id
  }
}`;

export const onCreateTeam = `subscription onCreateTeam($filter: ModelSubscriptionTeamFilterInput) {
  onCreateTeam(filter: $filter) {
    id
    club_id
    name
  }
}`;

export const onUpdateTeam = `subscription onUpdateTeam($filter: ModelSubscriptionTeamFilterInput) {
  onUpdateTeam(filter: $filter) {
    id
  }
}`;


export const onUpdateUserClubDocument = `subscription onUpdateUserClubDocument($user_id: String!, $filter: ModelSubscriptionUserClubDocumentFilterInput) {
  onUpdateUserClubDocument(user_id: $user_id, filter: $filter) {
    id
    user_id
    club_id
    id_card_status
    license_status
    medical_certificate_status
    health_questionnaire_status
    club_rules_status
    image_rights_form_status
  }
}`;
