import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { CText } from "../../components/Text";
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import { updateDocument } from '../../store/reducers/documents';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import './documents.css';
import { downloadFile, deleteUserClubDocuments, uploadUserClubDocuments } from '../../utils/file.js';
import CButton from "../../components/Button"

const Document = ({ data, index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userStored = useSelector(state => state?.user);
  const uploadInputRef = useRef(null);

  const [document, setDocument] = useState(data);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDocument(data);
  }, [data]);

  const addDocument = () => {
    uploadInputRef.current.click();
  };

  const handleFileSelection = async(event, club, user, fileType) => {
    setLoading(true);

    try {
      const userInfo = {
        id: user.info.id,
        documents_id: document.id
      };

      const userType = "PLAYER";

      const userFilesUpdated = await uploadUserClubDocuments(event, club, userInfo, fileType, userType);

      const updatedDocument = {
        ...document,
        status: "PENDING",
        path: userFilesUpdated.path,
      };

      setDocument(updatedDocument);

      dispatch(updateDocument(updatedDocument));
    } catch (error) {
      if (error.code === 1) {
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelUpload = async(user, fileType) => {
    setLoading(true);

    try {
      const userInfo = {
        id: user.info.id,
        documents_id: document.id,
        document_path: document.path,
        type: "PLAYER"
      };

      await deleteUserClubDocuments(userInfo, fileType, null);

      const updatedDocument = {
        ...document,
        status: "REQUESTED",
        path: ""
      };

      setDocument(updatedDocument);
      dispatch(updateDocument(updatedDocument));
    } catch (error) {
      if (error.code === 1) {
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid size={{ xs: 12 }} key={index}>
      <Grid container size={{ xs: 12 }} alignItems="center" className="documents-zone">
        <Grid container size={{ xs: 12 }} direction="row">
          <CText className="documents-date" level="p2" fontWeight={500} color="light">
            {document.requestedAt}
          </CText>
          {(document.status === "REQUESTED") && (
            <AlertIcon className="documents-status documents-status-icon documents-requested"/>
          )}
          {(document.status === "PENDING") && (
            <CText className="documents-status documents-pending" level="p3" fontWeight={700}>
              {t('documents.statuses.0.label')}
            </CText>
          )}
          {(document.status === "REJECTED") && (
            <CText className="documents-status documents-rejected" level="p3" fontWeight={700}>
              {t('documents.statuses.1.label')}
            </CText>
          )}
          {(document.status === "APPROVED") && (
            <CText className="documents-status documents-approved" level="p3" fontWeight={700}>
              {t('documents.statuses.2.label')}
            </CText>
          )}
        </Grid>
        
        <Grid container size={{ xs: 12 }} alignItems={document.status === "PENDING" ? "flex-start" : "stretch"}>
          <Grid container size={{ xs: 12, sm: 10 }} direction="row" alignItems="center">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <ClubPhoto
                className="documents-team-logo"
                src={document.club.logo}
              />
              <CText className="documents-list-title" level="p2" fontWeight={500} color="light">
                <span>
                  <span style={{ fontWeight: "700" }}>{document.club.name}</span>
                  <span> {t(`documents.document_labels.${document.type}`)}</span>
                </span>
              </CText>
            </div>
          </Grid>

          <Grid container size={{ xs: 12, sm: 2 }} direction="column" alignItems="flex-end" justifyContent='center'>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <input
                  id="uploadInput"
                  ref={uploadInputRef}
                  name=""
                  type="file"
                  hidden
                  style={{ width: '100%', cursor: 'pointer' }}
                  onChange={(e) => handleFileSelection(e, document.club.id, userStored, document.type)}
                />

                {(document.status === "REQUESTED" || document.status === "REJECTED") && (
                  <span
                    className="documents-icon-btn"
                    onClick={addDocument}
                  >
                    <UploadIcon className="documents-icon" />
                  </span>
                )}

                {(document.status === "PENDING") && (
                  <>
                    <span
                      className="documents-icon-btn"
                      onClick={() => downloadFile(document.path, null, document.type)}
                    >
                      <DownloadIcon className="documents-icon" />
                    </span>
                    <CButton
                      className="documents-btn documents-btn-update"
                      label={t('button.cancel')}
                      type="contained"
                      size={12}
                      onClick={() => cancelUpload(userStored, document.type)}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>

        {(document.status === "REJECTED" || document.status === "PENDING") && (
          <Grid container size={{ xs: 12 }} direction="row" justifyContent="flex-start" className="documents-info">
            <CText 
              className={`documents-info-label ${
                document.status === "REJECTED" ? "documents-rejected" :
                document.status === "PENDING" ? "documents-pending" : ""
              }`} 
              level="p3"
              fontWeight={600}
            >
              <InfoIcon
                className={`documents-info-icon ${
                  document.status === "REJECTED" ? "documents-rejected" : 
                  document.status === "PENDING" ? "documents-pending" : ""
                }`}
              />
              {
                document.status === "PENDING" ? t('documents.labels.0.label') : 
                document.status === "REJECTED" ? t('documents.labels.1.label') : ""
              }
            </CText>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Document;