import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom"
import { CText } from "../../components/Text";
import teamImg from '../../assets/images/team.svg';
import { CTab } from '../../components/Menu/index';
import ModalApp from '../../components/Modal/ModalApp.js';
import { ReactComponent as TeamFlagIcon } from '../../assets/icons/team-flag.svg';
import { ReactComponent as PlayerIcon } from '../../assets/icons/player.svg';
import { ReactComponent as CoachIcon } from '../../assets/icons/coach.svg';
import { ReactComponent as HourglassIcon } from '../../assets/icons/hourglass.svg';
import { ReactComponent as AnalyticalIcon } from '../../assets/icons/analytical.svg';
import { ReactComponent as SignUpIcon } from '../../assets/icons/sign-up.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as GroupIcon } from '../../assets/icons/group.svg';
import { ReactComponent as PitchIcon } from '../../assets/icons/pitch.svg';
import { ReactComponent as TrainingIcon } from '../../assets/icons/training.svg';
import Footer from './Footer.js';
import CButton from "../../components/Button";
import './animation.css';
import appLogo from "../../assets/images/app_logo.png"
import Lottie from "lottie-react";
import * as messageAnim from '../../assets/images/home-messages-animation.json';
import * as dataAnim from '../../assets/images/data-animation.json';
import './home.styles.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_BLUE = "#25a5e6";

const Timeline = ({ steps }) => {
  return (
    <div className="timeline">
      {steps.map((step, index) => (
        <div className="step-container" key={index}>
          <div className="step" key={index}>
            <div className="step-circle">
              {step.icon}
            </div>
            {index < steps.length - 1 && <div className="step-line"></div>}
          </div>
          <div className="step-text">{step.label}</div>
        </div>
      ))}
    </div>
  );
};

const Title = ({ children }) => {
  return (
    <div className="title">
      {children}
      <div className="title-bar" />
    </div>
  );
};

const SmallCard = ({ children }) => {
  return (
    <div className="card-small">
      {children}
    </div>
  );
};

const Card = ({ children }) => {
  return (
    <div className="card">
      {children}
    </div>
  );
};

const Home = (props) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [menuIndex, setMenuIndex] = useState(0);
  const [isIos, setIsIos] = useState(false);
  const [showIosPrompt, setShowIosPrompt] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const sectionRefs = useRef([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            }
          });
        },
        {
          threshold: 0.1
        }
      );

      sectionRefs.current.forEach((ref) => {
        if (ref) {
          observer.observe(ref);
        }
      });

      return () => {
        sectionRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref);
          }
        });
      };
    }, 300);

    return () => clearTimeout(timeoutId);
  }, []);
  
  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    
    if(isStandalone) {
      navigate(`/auth/sign_in`);
    } else {
      const savedPrompt = window.sessionStorage.getItem('deferredPrompt');
      const savedIsIos = window.sessionStorage.getItem('isIos');
    
      if (savedPrompt) setDeferredPrompt(savedPrompt);
      if (savedIsIos) setIsIos(savedIsIos === 'true');
    
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        window.sessionStorage.setItem('deferredPrompt', e);
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      setLoading(false);
      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }
  }, []);

  // Start - To remove
  const [showCounterLabel, setShowCounterLabel] = useState(true);
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass('fade-out');
      setTimeout(() => {
        setShowCounterLabel(prevState => !prevState);
        setFadeClass('fade-in');
      }, 1000);
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const handleInstallClick = () => {
    if (isIos) {
      setShowIosPrompt(true);
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
        } else {
          //console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    } else {
      //console.log('Install prompt not available');
    }
  };

  const renderIosInstructions = () => (
    <div className="iosPrompt">
      <p>{t('ios_instructions.0.label')}</p>
      <ol>
        <li>{t('ios_instructions.1.label')}<strong>{t('ios_instructions.2.label')}</strong>{t('ios_instructions.3.label')}</li>
        <li>{t('ios_instructions.4.label')}<strong>{t('ios_instructions.5.label')}</strong>.</li>
        <li>{t('ios_instructions.6.label')}<strong>{t('ios_instructions.7.label')}</strong>.</li>
      </ol>
      <button onClick={() => setShowIosPrompt(false)}>{t('ios_instructions.8.label')}</button>
    </div>
  );

  const addToRefs = (el) => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el);
    }
  };

  const howItWorkSteps = [
    {
      icon: <TeamFlagIcon className="step-menu-icon" />,
      label: `${t('home.user_menus.0.label')}`,
      steps: [
        {
          icon: <SignUpIcon className="step-icon" />,
          label: t('home.contents.5.label'),
        },
        {
          icon: <FilterIcon className="step-icon" />,
          label: t('home.contents.6.label'),
        },
        {
          icon: <GroupIcon className="step-icon" />,
          label: t('home.contents.7.label'),
        },
        {
          icon: <AnalyticalIcon className="step-icon" />,
          label: t('home.contents.8.label'),
        }
      ]
    },
    {
      icon: <CoachIcon className="step-menu-icon" />,
      label: `${t('home.user_menus.1.label')}`,
      steps: [
        {
          icon: <SignUpIcon className="step-icon" />,
          label: t('home.contents.9.label'),
        },
        {
          icon: <HourglassIcon className="step-icon" />,
          label: t('home.contents.10.label'),
        },
        {
          icon: <GroupIcon className="step-icon" />,
          label: t('home.contents.11.label'),
        },
        {
          icon: <AnalyticalIcon className="step-icon" />,
          label: t('home.contents.12.label'),
        }
      ]
    },
    {
      icon: <PlayerIcon className="step-menu-icon" />,
      label: `${t('home.user_menus.2.label')}`,
      steps: [
        {
          icon: <SignUpIcon className="step-icon" />,
          label: t('home.contents.13.label'),
        },
        {
          icon: <HourglassIcon className="step-icon" />,
          label: t('home.contents.14.label'),
        },
        {
          icon: <PitchIcon className="step-icon" />,
          label: t('home.contents.15.label'),
        },
        {
          icon: <TrainingIcon className="step-icon" />,
          label: t('home.contents.16.label'),
        }
      ]
    }
  ];

  const content = [
    {
      "class": "content1",
      "title": `${t('home.contents.0.title')}`,
      "titleColor": "title1",
      "text": `${t('home.contents.0.label')}`,
      "textColor": "text1",
      "image":
      <img 
        src={teamImg} 
        className="image"
        alt={`${t('home.contents.0.title')}`}
      />
    },
    {
      "class": "content2",
      "title": `${t('home.contents.1.title')}`,
      "titleColor": "title2",
      "text": `${t('home.contents.1.label')}`,
      "textColor": "text1",
      "image":
      <div
        style={{
          width: '100%',
          maxWidth: 400,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          perspective: '1000px'
        }}
      >
      <div
        style={{
          width: '100%',
          transform: 'rotateY(30deg) translateX(-3%)',
          transformOrigin: 'center'
        }}
      >
        <Lottie
          animationData={dataAnim}
          loop={true}
          autoplay={true}
        />
      </div>
    </div>
    },
    {
      "class": 'content1',
      "title": `${t('home.contents.2.title')}`,
      "titleColor": "title1",
      "text": `${t('home.contents.2.label')}`,
      "textColor": "text1",
      "image":
      <div className="phone-img-container">
        <div className="phone-img">
          <Lottie
            animationData={messageAnim}
            loop={true}
            autoplay={true}
          />
        </div>
      </div>
    }
  ];

  const fourth_section = [
    {
      "title": `${t('home.contents.19.title')}`,
      "text": `${t('home.contents.19.label')}`,
      "icon": <TeamFlagIcon className="card-icon" />
    },
    {
      "title": `${t('home.contents.20.title')}`,
      "text": `${t('home.contents.20.label')}`,
      "icon": <HourglassIcon className="card-icon" />
    },
    {
      "title": `${t('home.contents.21.title')}`,
      "text": `${t('home.contents.21.label')}`,
      "icon":  <AnalyticalIcon className="card-icon" />
    }
  ];

  const handleMenuIndex = (index) => {
    setMenuIndex(index);
  };

  return (
    !loading && (
      <div className="home-container">
        <Helmet>
          <title>{t('home.head.title')}</title>
          <meta name="description" content={t('home.head.meta')} />
        </Helmet>

        <div className="background-image-container">
          <div className="background-image"></div> 
          {showCounterLabel ? (
            <div
              className={`counter-content ${fadeClass} ${fadeClass === 'fade-out' ? 'hidden' : ''}`}
              style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}
            >
              <CText
                className="counter-label"
                color="light">
                  {t('counter.0.label')}
              </CText>

              <CText
                className="counter-label"
                color="light"
              >
                {t('counter.1.label')}{' '}
                <span className="counter-label-span">
                  {t('counter.2.label')}
                </span>
              </CText>

              <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <img src={appLogo} className="counter-app-logo"/>
                <CText
                  color="light"
                  className="counter-app-name"
                >
                  Football Connexion
                </CText>
              </span>
            </div>
          ) : (
            <div
              className={`${fadeClass} ${fadeClass === 'fade-out' ? 'hidden' : ''}`}
            >
              <div className="home-content">
                <CText
                  className="home-title"
                  color="light"
                >
                  {t('home.contents.3.title')}
                </CText>

                <CText
                  className="text"
                  color="light">
                    {t('home.contents.3.label')}
                </CText>
              </div>
            </div>
          )}

          { 1 === 0 ? <div className="installBtnWrapper">
            <CButton
              className="installBtn"
              label={t('home.buttons.0')}
              type="text"
              color={COLOR_PRIMARY}
              size={16}
              height={38}
              onClick={() => handleInstallClick()}
            />
          </div> :  null}
        </div>

          <div className="content">
            <div className="section section-slim fade-in-box" ref={addToRefs}>
              <Title>
                <CText
                  className="title-label"
                  color="light"
                >
                  {t('home.contents.4.title')}
                </CText>
              </Title>

              <CTab items={howItWorkSteps} onClickCB={handleMenuIndex} />
              <Timeline steps={howItWorkSteps[menuIndex].steps} />
            </div>

            <div className="section section-slim fade-in-box" ref={addToRefs}>
              <Title>
                <CText
                  className={"title-label"}
                  color="light"
                >
                  {t('home.contents.17.title')}
                </CText>
              </Title>

              {content.map((item, index) => (
                <Grid
                  container
                  spacing={(isXs || isSm) ? 2 : 1}
                  className={`functionalities ${item.class}`}
                  key={index}
                >
                  {((isXs || isSm) || index % 2 === 0) ? (
                    <>
                      <Grid size={{ xs: 12, md: 7 }}>
                      <CText className={`sub-title ${item.titleColor}`}>{item.title}</CText>
                      <CText className={`text ${item.textColor}`}>{item.text}</CText> 
                      </Grid>
                      <Grid size={{ xs: 12, md: 5 }} display="flex" justifyContent="center">
                        {item.image}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid size={{ xs: 12, md: 5 }} display="flex" justifyContent="center">
                        {item.image}
                      </Grid>
                      <Grid size={{ xs: 12, md: 7 }}>
                        <CText className={`sub-title ${item.titleColor}`}>{item.title}</CText>
                        <CText className={`text ${item.textColor}`}>{item.text}</CText>
                      </Grid>
                    </>
                  )}
                </Grid>
              ))}
            </div>

            <div className="section section-large fade-in-box" ref={addToRefs}>
              <Title>
                <CText
                  className={"title-label"}
                  color="light"
                >
                  {t('home.contents.18.title')}
                </CText>
              </Title>

              <Grid
                container
                spacing={2}
                className="section-content"
              >
                {fourth_section.map((item, index) => (
                  <Grid
                    key={index}
                    size={{ xs: 12, sm: 6, md: 4, lg: 4 }}
                    className={"card-wrapper"}
                  >
                    <SmallCard>
                      {item.icon}
                      <CText className={"card-small-title"} color="light">
                        {item.title}
                      </CText>
                      <CText className={"text"} color="light">
                        {item.text}
                      </CText>
                    </SmallCard>
                  </Grid>
                ))}
              </Grid>
            </div>

            <div className="section section-large fade-in-box" ref={addToRefs}>
              <Title>
                <CText
                  className={"title-label"}
                  color="light"
                >
                  {t('home.contents.22.title')}
                </CText>
              </Title>

              <div className="pitch-card-container">
                <div className="pitch-card">
                  <div className="player player-1">1</div>
                  <div className="player player-2">2</div>
                  <div className="player player-3">3</div>
                  <div className="player player-4">4</div>
                  <div className="player player-5">5</div>
                  <div className="player player-6">6</div>
                  <div className="player player-7">7</div>
                  <div className="player player-8">8</div>
                  <div className="player player-9">9</div>
                  <div className="player player-10">10</div>
                  <div className="player player-11">11</div>
                </div>

                <div className="pitch-card-content">
                  <div className="pitch-card-content-inner">
                    <CText className={"pitch-card-title"} color="light">
                      {t('home.contents.22.label')}
                    </CText>
                    <div className="pitch-card-btn">
                      <CButton
                        label={t('home.buttons.1')}
                        type="contained"
                        color={COLOR_BLUE}
                        size={16}
                        height={38}
                        onClick={() => navigate("/auth/sign_up")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <Footer />

        <ModalApp
          open={showIosPrompt}
          onClose={() => setShowIosPrompt(false)}
        >
          {renderIosInstructions()}
        </ModalApp>
      </div>
    )
  );
};

export default Home;