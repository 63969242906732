import { API, Storage  } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { getSession } from "./auth";

class UploadFileError extends Error {
  constructor(code, message) {
    super(message);
    this.code = code;
  }
}

const fileTypeConfig = {
  license: 'license',
  id_card: 'id_card',
  medical_certificate: 'medical_certificate',
  health_questionnaire: 'health_questionnaire',
  club_rules: 'club_rules',
  image_rights_form: 'image_rights_form'
};

export const uploadUserClubDocuments = async (event, clubId, user, fileType, userType) => {
  try {
    const fileData = event?.target?.files[0];
    const fileExtensions = {
      'image/png': 'png',
      'image/jpeg': 'jpeg',
      'image/webp': 'webp',
      'application/pdf': 'pdf',
      'text/plain': 'txt',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'text/csv': 'csv',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'application/vnd.oasis.opendocument.text': 'odt',
    };

    const fileExtension = fileExtensions[fileData.type];

    if (!fileExtension) {
      throw new UploadFileError(1, 'Invalid file format.');
    }

    const updateData = { id: user.documents_id };
    const timestamp = Date.now();
    let filePath;
    const isPlayer = userType === 'PLAYER';
    const isCoach = userType === 'COACH';
    const isClubAdmin = userType === 'CLUBADMIN';
    const newStatus = isPlayer ? 'PENDING' : (isCoach || isClubAdmin) ? 'APPROVED' : null;
    
    if (!fileTypeConfig[fileType]) throw new Error('Invalid file type.');
    const folderName = fileTypeConfig[fileType];

    filePath = `clubs/${clubId}/users/${user.id}/${folderName}/${timestamp}.${fileExtension}`;
    updateData[`${fileType}_path`] = filePath;
    user.path = filePath;
    user.path = filePath;

    updateData[`${fileType}_status`] = newStatus;
    user.status = newStatus;

    await getSession();
    await Storage.put(filePath, fileData, { contentType: fileData.type });

    await API.graphql({
      query: mutations.updateUserClubDocument,
      variables: { input: updateData },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    return user;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteUserClubDocuments = async (user, fileType, type) => {
  try {
    const updateData = { id: user.documents_id };
    let filePath;

    const documentStatus = 'REQUESTED';
    
    if (!fileTypeConfig[fileType]) throw new UploadFileError(2, 'Invalid file type.');
    
    const pathKey = `${fileTypeConfig[fileType]}_path`;
    filePath = user.document_path ? user.document_path : user[pathKey];
    
    updateData[pathKey] = null;
    user.path = null;
    
    if (type === "DELETION") {
      updateData[`${fileType}_status`] = "NONE";
      user.status = "NONE";
      updateData[`${fileType}_requested_at`] = null;
    } else {
      updateData[`${fileType}_status`] = documentStatus;
    }
    
    await getSession();

    await API.graphql({
      query: mutations.updateUserClubDocument,
      variables: { input: updateData },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    await Storage.remove(filePath);

    return user;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const downloadFile = async (path, fullName, fileType) => {
  try {
    const fileData = await Storage.get(path, { download: true });

    const blob = new Blob([fileData.Body], { type: fileData.ContentType });

    const sanitizedFileType = fileType.replace(/\s/g, '_');
    const timestamp = Date.now();

    const fileName = fullName
    ? `${fullName.replace(/\s/g, '_')}_${sanitizedFileType}.${path.split('.').pop()}`
    : `${sanitizedFileType}_${timestamp}.${path.split('.').pop()}`;

    const link = document.createElement('a');
    link.download = fileName;
    link.href = URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.error(err);
  }
};