import { createSlice } from '@reduxjs/toolkit';

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    documents: [],
    requested_count: 0,
  },
  reducers: {
    setDocuments: (state, action) => {
      state.documents = action.payload;
      state.requested_count = action.payload.filter(doc => doc.status === "REQUESTED").length;
    },
    updateDocument: (state, action) => {
      const updatedDocument = action.payload;

      // Recherche l'index du document à mettre à jour
      const index = state.documents.findIndex(
        doc => doc.id === updatedDocument.id && doc.type === updatedDocument.type
      );

      if (index !== -1) {
        // Met à jour le document existant
        state.documents[index] = updatedDocument;
      } else {
        // Ajoute un nouveau document si non trouvé
        state.documents.push(updatedDocument);
      }

      // Recalcule le nombre de documents REQUESTED
      state.requested_count = state.documents.filter(doc => doc.status === "REQUESTED").length;
    },
  },
});

export const { setDocuments, updateDocument } = documentsSlice.actions;

export default documentsSlice.reducer;
