import React from 'react';
import MUISwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';

const SwipeableDrawer = ({ anchor = "right", open, toggleDrawer, children }) => {
  return (
    <MUISwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      style={{
        zIndex: 100000
      }}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#1A1919"
        }
      }}
    >
      <Box
        className="drawer-background"
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {children}
      </Box>
    </MUISwipeableDrawer>
  );
};

export default SwipeableDrawer;
