import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getRealTimeInvitations } from "../utils/invitation";
import { fetchConversation, messagesCounter } from "../utils/conversation";
import { getCurrentSeason } from "../utils/season";
import Toolbar from '@mui/material/Toolbar';
import { SideMenu } from "../components/Menu/Menu.js";
import TopBar from "../components/TopBar/TopBar.js";
import { Profile as ClubProfile } from "./Clubs/Profile";
import { NewClub } from "./Clubs/NewClub";
import UserTeams from "./Teams/UserTeams";
import NotFound from "./NotFound";
import Home from "./Home/Home";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";
import NewPassword from "./Auth/NewPassword";
import VerificationCode from "./Auth/VerificationCode";
import { getSession } from "../utils/auth";
import * as subscriptions from '../graphql/subscriptions';
import { PrivateRoute, PublicRoute } from "../components/Route.js";
import ScrollToTop from "./ScrollToTop";
import Documents from "./Documents/Documents";
import AccountEdition from "./Account/Edition";
import TeamEdition from "./Teams/TeamEdition";
import PwdRecoveringInitiate from "./Recover/Initiate";
import PwdRecoveringCode from "./Recover/Code";
import PwdRecover from "./Recover/Password";
import AfterGame from "./Matches/AfterGame";
import PlayerGameSummary from "./Matches/PlayerGameSummary";
import PreGame from "./Matches/PreGame";
import Match from "./Matches/Match";
import GoalDescriptionApp from "./Matches/GoalDescriptionCreator.js";
import Calendar from "./Calendar/Calendar";
import { Profile as CoachProfile } from "./Users/Profile";
import { Stats as TeamStats } from "./Teams/Stats/Stats";
import Invitations from "./Invitations/Invitations";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import Players from "./Teams/Players";
import Language from "./Settings/Language";
import Teams from "./Teams/Teams";
import Tactics from "./Teams/Tactics.js";
import TeamPlayer from "./Teams/TeamPlayer";
import TeamDocuments from "./Teams/TeamDocuments";
import Subscriptions from "./Subscriptions/Subscriptions";
import Plans from "./Subscriptions/Plans";
import Messages from "./Conversations/Messages.js";
import { createTheme, ThemeProvider } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { setDocuments, updateDocument } from '../store/reducers/documents';
import * as queries from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pt';

const COLOR_LIGHT = "#f4f4f4";
const COLOR_PRIMARY = "#45d69f";
const COLOR_PINK= "#e92163";
const HORIZONTAL_PADDING = '6%';
const TOP_BAR_HEIGHT = 56;
const VERTICAL_CONTENT_PADDING = 25;
const MENU_WIDTH = 250;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    pink: {
      main: COLOR_PINK,
      light: "#f06d94",
      dark: "#a60e46"
    }
  },
});

const App = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  moment.locale(currentLang);

  const user = useSelector(state => state?.user);
  const userId = user?.info?.id;
  const teamId = user?.club?.team?.id;

  const [isPwa, setIsPwa] = useState(false);
  const MessageSubscriptionRef = useRef(null);
  const ConversationSubscriptionRef = useRef(null);
  const InvitationSubscriptionRef = useRef(null);

  useEffect(() => {
    const initializeSubscriptions = async () => {
      if (!userId || !teamId) return;
      
      // Vérifiez que l'utilisateur est connecté
      const session = await getSession();
      if (!session) return;

      const currentSeason = await getCurrentSeason();
      await subscribeToNewConversations(currentSeason);

      const conversation = await fetchConversation(teamId);
      if (conversation && conversation.id) {
        startMessageSubscription(conversation.id);
      }

      // Démarrer la souscription pour les invitations en temps réel
      InvitationSubscriptionRef.current = await getRealTimeInvitations(userId, true, dispatch);
    };

    if(user?.groups?.includes("Coaches") || user?.groups?.includes("Players")) {
      initializeSubscriptions();
    }

    return () => unsubscribeAll();
  }, [userId, teamId, dispatch]);

  // Documents subscription
  useEffect(() => {
    const fetchRequestedDocuments = async () => {
      try {
        const filter = {
          user_id: { eq: userId }
        };

        const listUserClubDocumentsResp = await API.graphql({
          query: queries.listUserClubDocuments,
          variables: { filter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        const listDocuments = listUserClubDocumentsResp.data?.listUserClubDocuments?.items || [];
        const requestedDocuments = [];

        listDocuments.forEach(doc => {
          const { club, id } = doc;

          const documentStatuses = [
            { type: "health_questionnaire", path: doc.health_questionnaire_path, status: doc.health_questionnaire_status, requestedAt: doc.health_questionnaire_requested_at },
            { type: "image_rights_form", path: doc.image_rights_form_path, status: doc.image_rights_form_status, requestedAt: doc.image_rights_form_requested_at },
            { type: "license", path: doc.license_path, status: doc.license_status, requestedAt: doc.license_requested_at },
            { type: "medical_certificate", path: doc.medical_certificate_path, status: doc.medical_certificate_status, requestedAt: doc.medical_certificate_requested_at },
            { type: "club_rules", path: doc.club_rules_path, status: doc.club_rules_status, requestedAt: doc.club_rules_requested_at },
            { type: "id_card", path: doc.id_card_path, status: doc.id_card_status, requestedAt: doc.id_card_requested_at }
          ];

          documentStatuses.forEach(item => {
            if ((item.status !== null || item.status !== 'NONE') && item.requestedAt !== null) {
              const localizedFormat = moment.localeData(currentLang).longDateFormat('L').replace(/\//g, '-');

              requestedDocuments.push({
                id: id,
                club: {
                  id: club.id,
                  name: JSON.parse(club.names[0]).name,
                  logo: club.logo
                },
                type: item.type,
                path: item.path,
                status: item.status,
                requestedAt: moment(item.requestedAt).format(localizedFormat)
              });
            }
          });
        });

        dispatch(setDocuments(requestedDocuments));
      } catch (error) {
        //console.error("Error fetching requested documents:", error);
      }
    };

    fetchRequestedDocuments();
  }, [dispatch, userId, currentLang]);
  
  const subscribeToNewConversations = async (season) => {
    if (!teamId || ConversationSubscriptionRef.current) return;

    ConversationSubscriptionRef.current = await API.graphql(
      graphqlOperation(subscriptions.onCreateConversation, { team_id: teamId, season })
    ).subscribe({
      next: async ({ value: { data } }) => {
        const newConversation = data.onCreateConversation;
        if (newConversation) {
          startMessageSubscription(newConversation.id);
        }
      },
      error: error => console.error('Erreur de souscription à la création de conversation:', error),
    });
  };

  const startMessageSubscription = async (conversationId) => {
    if (MessageSubscriptionRef.current) {
      MessageSubscriptionRef.current.unsubscribe();
    }

    MessageSubscriptionRef.current = await messagesCounter(
      userId,
      teamId,
      conversationId,
      true,
      dispatch
    );
  };

  const unsubscribeAll = () => {
    if (MessageSubscriptionRef.current) {
      MessageSubscriptionRef.current.unsubscribe();
      MessageSubscriptionRef.current = null;
    }
    if (ConversationSubscriptionRef.current) {
      ConversationSubscriptionRef.current.unsubscribe();
      ConversationSubscriptionRef.current = null;
    }
    if (InvitationSubscriptionRef.current) {
      InvitationSubscriptionRef.current.unsubscribe();
      InvitationSubscriptionRef.current = null;
    }
  };

  const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      height: TOP_BAR_HEIGHT,
      backgroundColor: '#1A1919',
      paddingLeft: HORIZONTAL_PADDING,
      paddingRight: HORIZONTAL_PADDING
    },
    appContainer: {
      display: 'flex'
    },
    ligthContentZone: {
      backgroundColor: COLOR_LIGHT
    },
    contentWrapper: {
      width: '100%'
    },
    content: {
      flexGrow: 1,
      paddingTop: 0,
      paddingBottom: 0,
      paddingTop: 15,
      paddingBottom: 15,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: '3%',
        paddingRight: '3%'
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 30,
        paddingRight: 30
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 45,
        paddingRight: 45
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 60,
        paddingRight: 60
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 80,
        paddingRight: 80
      }
    },
    content2: {
      display: 'flex',
      flexGrow: 1,
      paddingTop: 0,
      paddingBottom: 0,
      paddingTop: 15,
      paddingBottom: 15
    },
    menuIcon: {
      marginRight: 8,
      width: 23,
      height: 23,
      fill: COLOR_LIGHT,
      stroke: COLOR_LIGHT
    },
    menuIconFocus: {
      marginRight: 8,
      width: 23,
      height: 23,
      fill: COLOR_PRIMARY,
      stroke: COLOR_PRIMARY
    }
  }));

  const classes = useStyles();
  const isMobileApp = Capacitor.isNativePlatform();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {(isPwa || isMobileApp) ?
            <Route path="/" element={<Navigate to="/auth/sign_in" />} />
            :
            <Route path="/" element={
              <PublicRoute>
                <TopBar />
                <Toolbar />
                <div className={classes.appContainer}>
                  <div className={classes.content2}>
                    <Home />
                  </div>
                </div>
              </PublicRoute>
            } />
          }
          <Route path="/accounts/edit" element={
            <PrivateRoute>
              <TopBar btnMenu={true} btnCaret={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <AccountEdition />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/auth/new_password" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <NewPassword />
            </PublicRoute>
          } />
          <Route path="/auth/sign_in" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <SignIn />
            </PublicRoute>
          } />
          <Route path="/auth/sign_up" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <SignUp />
            </PublicRoute>
          } />
          <Route path="/auth/verification_code" element={
            <PublicRoute>
              <TopBar btnSignIn={false} />
              <Toolbar />
              <VerificationCode />
            </PublicRoute>
          } />
          <Route path="/calendar_results" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Calendar />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Teams />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/edit" element={
            <PrivateRoute>
              <TopBar btnMenu={true} btnCaret={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <TeamEdition />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/messages" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <Messages />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/tactics" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Tactics />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/players" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Players />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route exact path="/teams/:teamId/players/:playerId" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <SideMenu />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <TeamPlayer />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/teams/:teamId/stats" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <SideMenu />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <TeamStats />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/teams/:teamId/documents" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <SideMenu />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <TeamDocuments />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/documents" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Documents />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          {user.groups.includes("Players") || user.groups.includes("Coaches") ?
            <Route exact path="/users/:id/teams" element={
              <PrivateRoute>
                <TopBar />
                <Toolbar />
                <div className={classes.appContainer}>
                  <SideMenu />
                  <div className={classes.contentWrapper}>
                    <div className={classes.content}>
                      <UserTeams />
                    </div>
                  </div>
                </div>
              </PrivateRoute>
            } />
            :
            <Route exact path="/clubs/:id/teams" element={
              <PrivateRoute>
                <TopBar />
                <Toolbar />
                <div className={classes.appContainer}>
                  <SideMenu />
                  <div className={classes.contentWrapper}>
                    <div className={classes.content}>
                      <UserTeams />
                    </div>
                  </div>
                </div>
              </PrivateRoute>
            } />          
          }
          <Route path="/clubs/new" element={
            <PrivateRoute>
              <TopBar btnSignIn={false} btnCaret={false} />
              <Toolbar />
              <NewClub />
            </PrivateRoute>
          } />
          <Route path="/clubs/:id" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <ClubProfile />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/invitations" element={
            <PrivateRoute>
              <TopBar />
              <Toolbar />
              <div className={classes.appContainer}>
                <SideMenu />
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <Invitations />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/coaches/:userId" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.contentWrapper}>
                <div className={classes.content}>
                  <CoachProfile />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/matches/:matchId" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <Match />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/matches/:matchId/pre_game" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <PreGame />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/matches/:matchId/after_game" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <AfterGame />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/matches/after_game/players/:id" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <PlayerGameSummary />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/plans" element={
            <PublicRoute>
              <TopBar btnMenu={false} btnCaret={false} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <Plans />
                </div>
              </div>
            </PublicRoute>
          } />
          <Route path="/players/:userId" element={
            <PrivateRoute>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.contentWrapper}>
                  <div className={classes.content}>
                    <CoachProfile />
                  </div>
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/settings/language" element={
            <>
              <TopBar btnMenu={true} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <Language />
                </div>
              </div>
            </>
          } />
          <Route path="/recover/initiate" element={
            <PublicRoute>
              <TopBar />
              <Toolbar />
              <PwdRecoveringInitiate />
            </PublicRoute>
          } />
          <Route path="/recover/code" element={
            <PublicRoute>
              <TopBar />
              <Toolbar />
              <PwdRecoveringCode />
            </PublicRoute>
          } />
          <Route path="/recover/password" element={
            <PublicRoute>
              <TopBar />
              <Toolbar />
              <PwdRecover />
            </PublicRoute>
          } />
          <Route path="/subscriptions" element={
            <PrivateRoute>
              <TopBar btnMenu={false} btnCaret={false} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <Subscriptions />
                </div>
              </div>
            </PrivateRoute>
          } />
          <Route path="/terms_and_conditions" element={
            <PublicRoute>
              <TopBar btnMenu={false} btnCaret={false} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <TermsAndConditions />
                </div>
              </div>
            </PublicRoute>
          } />
          <Route path="/test/goal-description" element={
            <>
              <TopBar btnMenu={false} btnCaret={false} />
              <Toolbar />
              <div className={classes.appContainer}>
                <div className={classes.content}>
                  <GoalDescriptionApp />
                </div>
              </div>
            </>
          } />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;