import React, { useState, useEffect, useRef } from 'react';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots-vertical.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/mail.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import ProfilePicture from "../ProfilePicture/index";
import Checkbox from "../Checkbox/Checkbox.js";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { downloadFile, deleteUserClubDocuments, uploadUserClubDocuments } from '../../utils/file.js';
import { SnackBarError } from "../../components/SnackBar";
import { CText } from "../../components/Text";
import * as mutations from '../../graphql/mutations';

const COLOR_PRIMARY = '#45d69f'
const COLOR_LIGHT = "#f4f4f4"
const COLOR_PINK= "#e92163"
const COLOR_BLUE = "#25a5e6"
const COLOR_DARK = '#242834'
const COLOR_ORANGE= "#f2a624";
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const ZONE_BACKGROUND_COLOR = "#1f2126"
const ROW_COLOR_1 = '#24262a'
const ROW_COLOR_2 = '#1d2027'
const BUTTON_WIDTH = 100

const statusIndexMapping = {
  NONE: 0,
  REQUESTED: 1,
  PENDING: 2,
  REJECTED: 3
};

const useStyles = makeStyles((theme) => ({
  cardIcon: {
    width: 18,
    height: 18
  },
  icon: {
    width: 20,
    height: 20,
    fill: COLOR_LIGHT
  },
  iconBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20+6,
    height: 20+6,
    cursor: 'pointer',
    borderRadius: 25,
    "&:hover": {
      backgroundColor: '#232e2f',
      filter: 'brightness(90%)',
      boxShadow: 'none'
    }
  },
  btnZone: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 10
  },
  playerPhoto: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: '1px solid',
    marginRight: 10
  },
  headCell: {
    fontSize: '14px !important',
    fontWeight: '700 !important'
  },
  cell: {
    color: `${COLOR_LIGHT} !important`,
    borderBottom: 'none !important',
    minWidth: '100px !important'
  },
  cellContent: {
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    height: '100%'
  },
  cellContentWithSort: {
    marginLeft: -22
  },
  stickyCell: {
    position: 'sticky !important',
    left: '0px !important',
    width: '180px !important',  
    zIndex: `100 !important`,
    marginTop: 1,
    display: 'flex !important',
    flexDirection: 'row !important',
    alignItems: 'center !important'
  },
  stickyCellColor1: {
    borderTopLeftRadius: `16px !important`,
    backgroundColor: `${ZONE_BACKGROUND_COLOR} !important`
  },
  stickyCellColor2: {
    backgroundColor: `${ROW_COLOR_1} !important`
  },
  stickyCellColor3: {
    backgroundColor: `${ROW_COLOR_2} !important`
  },
  listIcon: {
    width: 17,
    height: 17,
    fill: COLOR_DARK,
    stroke: COLOR_DARK
  },
  listIconZone: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center'
  },
  status: {
    position: 'absolute',
    bottom: -17
  },
  statusBadge: {
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 10
  },
  statusLabel: {
    fontSize: 10,
    color: COLOR_DARK,
    fontWeight: '700 !important'
  },
  statusApproved: {
    backgroundColor: COLOR_PRIMARY
  },
  statusPending: {
    backgroundColor: COLOR_ORANGE
  },
  statusNone: {
    backgroundColor: COLOR_PINK
  },
  statusRequested: {
    backgroundColor: COLOR_BLUE,
    position: 'none !important'
  }
}))

const createData = (id, player, photo_path, position, documents_id, license, contribution, id_card, club_rules, health_questionnaire, medical_certificate, image_rights_form) => {
  return {
    id,
    player,
    photo_path,
    position,
    documents_id,
    license,
    contribution,
    id_card,
    club_rules,
    health_questionnaire,
    medical_certificate,
    image_rights_form
  }
}

const  descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const  stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const headCells = [
    {
      id: "player",
      align: 'left',
      numeric: false,
      disablePadding: false,
      label: props.translation('team_documents.arrays.0.headers.0'),
      icon: null
    },
    {
      id: "position",
      align: 'center',
      numeric: false,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.1'),
      icon: null
    },
    {
      id: "document_1",
      align: 'center',
      numeric: true,
      disablePadding: false,
      label: props.translation('team_documents.arrays.0.headers.2'),
      icon: null
    },
    {
      id: "document_2",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.3'),
      icon: null
    },
    {
      id: "document_3",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.4'),
      icon: null
    },
    {
      id: "document_4",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.5'),
      icon: null
    },
    {
      id: "document_5",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.6'),
      icon: null
    },
    {
      id: "document_6",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.7'),
      icon: null
    },
    {
      id: "document_7",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('team_documents.arrays.0.headers.8'),
      icon: null
    }
  ]

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            className={`${classes.cell} ${classes.headCell}  ${index === 0 ? `${classes.stickyCell} ${classes.stickyCellColor1}` : ''}`}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {index < 2 ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  cursor: 'pointer',
                  '&.Mui-selected': {
                    '& > *': {
                      color: COLOR_LIGHT,
                    },
                  },
                  '&.Mui-active': {
                    color: COLOR_LIGHT,
                    '& .MuiSvgIcon-root:not(.Mui-active)': {
                      fill: COLOR_LIGHT
                    }
                  },
                  '&:hover': {
                    color: COLOR_LIGHT,
                    filter: 'brightness(90%)',
                    '& > *': {
                      filter: 'brightness(90%)'
                    }
                  }
                }}
              >
                {headCell.icon ?
                  <Tooltip title={`${headCell.label}`}>
                    {headCell.icon}
                  </Tooltip>
                  : headCell.label
                }
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              :
              <div>
                {headCell.icon ?
                  <Tooltip title={`${headCell.label}`}>
                    {headCell.icon}
                  </Tooltip>
                  : headCell.label
                }
              </div>
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const DocumentCell = ({ fileType, label, data, index, row, t, userStored, onChange }) => {
  const classes = useStyles();
  const uploadInputRef = useRef(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("false");

  const userType = userStored?.groups[0] === 'Coaches' 
    ? 'COACH' 
    : userStored?.groups[0] === 'ClubAdmins'
    ? 'CLUBADMIN' 
    : null;
  
  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const handleFileSelection = async (event, index, row, fileType) => {
    try {
      const userFilesUpdated = await uploadUserClubDocuments(event, userStored?.club?.id, row, fileType, userType);
      onChange(index, { ...row, [fileType]: userFilesUpdated[`${fileType}`] });
    } catch (error) {
      if(error.code === 1) {
        setSnackBarErrorMessage(t('team_documents.errors.0.message'));
        setOpenSnack(true);
      }
    }
  };

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setSnackBarErrorMessage("")
  };

  const addDocument = () => {
    handleClose();
    uploadInputRef.current.click();
  };

  const deleteDocument = async(row, index, fileType, type) => {
    handleClose();
    const userFilesUpdated = await deleteUserClubDocuments(row, fileType, type);
    onChange(index, { ...row, [fileType]: userFilesUpdated[`${fileType}`] });
  };

  const updateDocumentStatus = async (documentId, newStatus) => {
    handleClose();
    const updatedData = { ...data, status: newStatus };
    onChange(index, { ...row, [fileType]: updatedData });

    try {
      const input = {
        id: documentId,
        [`${fileType}_status`]: newStatus,
        ...(newStatus === "REQUESTED" && { [`${fileType}_requested_at`]: new Date().toISOString() })
      };

      await API.graphql({
        query: mutations.updateUserClubDocument,
        variables: { input: input },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut du document:', error);
    }
  };

  return (
    <div className={`${classes.cellContent} ${classes.cellDocument}`}>
      <input
        id="uploadInput"
        ref={uploadInputRef}
        name=""
        type="file"
        hidden
        style={{ width: '100%', cursor: 'pointer' }}
        onChange={(e) => handleFileSelection(e, index, row, fileType)}
      />

      {data?.status !== 'APPROVED' ?
        <span
          className={`
            ${classes.statusBadge}
            ${data?.status !== 'REQUESTED' ? classes.status : null}
            ${
              data?.status === 'PENDING' ? classes.statusPending :
              data?.status === 'REQUESTED' ? classes.statusRequested :
              classes.statusNone
            }
          `}
        >
          <CText className={`${classes.statusLabel}`}>
            {t(`team_documents.statuses.${statusIndexMapping[data?.status || 'NONE']}.label`)}
          </CText>
        </span>
        : null
      }

      {data?.status !== 'REQUESTED' ? (
        <div className={`${classes.btnZone}`}>
          {data?.path ? (
            <div className={`${classes.iconBtn}`}>
              <Tooltip title={t('team_documents.contents.0.labels.0')}>
                <DownloadIcon
                  className={`${classes.icon}`}
                  onClick={() => downloadFile(data?.path, row.player, label)}
                />
              </Tooltip>
            </div>
          ) : null}

          {data?.status !== 'REJECTED' ? (
            <div className={`${classes.iconBtn}`}>
              <Tooltip title={t('team_documents.contents.0.labels.3')}>
                <DotsIcon
                  className={`${classes.icon}`}
                  onClick={handleClickMore}
                />
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minWidth: '10ch'
                  },
                }}
                autoFocus={false}
              >
                {data.status === 'NONE' || !data.status ? (
                  [
                    <MenuItem key="upload" onClick={addDocument}>
                      <span className={classes.listIconZone}>
                        <UploadIcon className={classes.listIcon} />
                      </span>
                      {t('team_documents.contents.0.labels.1')}
                    </MenuItem>,
                    <MenuItem key="request" onClick={() => updateDocumentStatus(row.documents_id, 'REQUESTED')}>
                      <span className={classes.listIconZone}>
                        <MailIcon className={classes.listIcon} />
                      </span>
                      {t('team_documents.contents.0.labels.4')}
                    </MenuItem>
                  ]
                ) : data.status === 'PENDING' ? (
                  [
                    <MenuItem key="approve" onClick={() => updateDocumentStatus(row.documents_id, 'APPROVED')}>
                      <span className={classes.listIconZone}>
                        <CheckIcon className={classes.listIcon} />
                      </span>
                      {t('team_documents.contents.0.labels.5')}
                    </MenuItem>,
                    <MenuItem key="reject" onClick={() => updateDocumentStatus(row.documents_id, 'REJECTED')}>
                      <span className={classes.listIconZone}>
                        <CrossIcon className={classes.listIcon} />
                      </span>
                      {t('team_documents.contents.0.labels.6')}
                    </MenuItem>
                  ]
                ) : data.status === 'APPROVED' ? (
                  [
                    <MenuItem key="delete" onClick={() => deleteDocument(row, index, fileType, "DELETION")}>
                      <span className={classes.listIconZone}>
                        <TrashIcon className={classes.listIcon} />
                      </span>
                      {t('team_documents.contents.0.labels.2')}
                    </MenuItem>
                  ]
                ) : null}
              </Menu>
            </div>
          ) : null}
        </div>
      ) : null}

      <SnackBarError
        open={openSnack}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </div>
  );
};

const PlayersDocumentsTable = ({ data, clubId, season }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);

  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("")
  const [selected, setSelected] = React.useState([])
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      const newRows = data.map((item) => {
        const fullName = `${item.first_name} ${item.last_name}`;
        return createData(
          item.id,
          fullName,
          item.photo_path,
          item.position,
          item.documents.id,
          { path: item.documents.license.path, status: item.documents.license.status },
          { id: item.contribution.id, paid: item.contribution.paid },
          { path: item.documents.id_card.path, status: item.documents.id_card.status },
          { path: item.documents.club_rules.path, status: item.documents.club_rules.status },
          { path: item.documents.health_questionnaire.path, status: item.documents.health_questionnaire.status },
          { path: item.documents.medical_certificate.path, status: item.documents.medical_certificate.status },
          { path: item.documents.image_rights_form.path, status: item.documents.image_rights_form.status }
        );
      });
      setRows(newRows);
    }
  }, [data]);

  const visibleRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy));
  }, [rows, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.player)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const onChangeContribution = async(value, data) => {
    try {
      const updateData = {
        id: data.contribution.id,
        paid: value
      }

      await API.graphql({
        query: mutations.updateUserClubContribution,
        variables: {input: updateData},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
  
      const newContributionPaid = value
  
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === data.id
            ? {
                ...row,
                contribution: {
                  ...row.contribution,
                  id: row.contribution.id,
                  paid: newContributionPaid
                },
              }
            : row
        )
      );
    } catch (err) {
      //console.log('error fetching:', err);
    }
  }

  const onChangeDocuments = (index, updatedRow) => {
    setRows((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, ...updatedRow } : row
      )
    );
  };
    
  return (
    <TableContainer>
      <Table
        sx={{}}
        aria-labelledby="tableTitle"
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          translation={t}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const labelId = `table-${index}`
            const rowColor = index % 2 === 0 ? ROW_COLOR_1 : ROW_COLOR_2;

            return (
              <TableRow
                tabIndex={-1}
                key={row.id}
                sx={{
                  backgroundColor: rowColor,
                  borderBottom: 'none',
                  color: COLOR_LIGHT
                }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  className={`
                    ${classes.cell}
                    ${classes.stickyCell}
                    ${classes.stickyCellColor1}
                    ${index%2 === 0 ? `${classes.stickyCellColor2}` : `${classes.stickyCellColor3}`}
                  `}
                >
                  <div>
                    <ProfilePicture className={classes.playerPhoto} src={row.photo_path} />
                  </div>
                  {row.player}
                </TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                  <span className={`${classes.cellContent} ${classes.cellContentWithSort}`}>
                    {row.position}
                  </span>
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="license"
                    label={t(`team_documents.arrays.0.headers.2`)}
                    data={row.license}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <div className={`${classes.cellContent}`}>
                    <Checkbox
                      onChange={(value) => onChangeContribution(value, row)}
                      defaultValue={row.contribution.paid}
                    />
                  </div>
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="id_card"
                    label={t(`team_documents.arrays.0.headers.4`)}
                    data={row.id_card}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="medical_certificate"
                    label={t(`team_documents.arrays.0.headers.5`)}
                    data={row.medical_certificate}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="health_questionnaire"
                    label={t(`team_documents.arrays.0.headers.6`)}
                    data={row.health_questionnaire}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="club_rules"
                    label={t(`team_documents.arrays.0.headers.7`)}
                    data={row.club_rules}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                  <DocumentCell
                    fileType="image_rights_form"
                    label={t(`team_documents.arrays.0.headers.8`)}
                    data={row.image_rights_form}
                    index={index}
                    row={row}
                    t={t}
                    userStored={userStored}
                    onChange={onChangeDocuments}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PlayersDocumentsTable;