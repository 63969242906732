import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { styled } from '@mui/material/styles';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import { getSession } from "../../utils/auth";
import { useDispatch } from 'react-redux';
import { useStylesMenu, useStylesSideMenu } from './Menu.styles.js';
import { ReactComponent as RightChevronIcon } from '../../assets/icons/right-chevron.svg';
import { CText } from "../Text.js";
import { menuList } from "./list.js";
import { getRealTimeInvitations } from '../../utils/invitation';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions';
import { getCurrentSeason } from "../../utils/season";
import * as queries from '../../graphql/queries';

const COLOR_LIGHT = "#f4f4f4";
const MENU_BACKGROUND_COLOR = "rgba(28, 30, 33, 1)";
const MENU_LABEL_COLOR = "rgba(242, 242, 242, 1)";
const MENU_LABEL_ACTIVE_COLOR = "rgba(69, 214, 159, 1)";
const MENU_LABEL_ACTIVE_BACKGROUND_COLOR = "rgba(69, 214, 159, 0.07)";
const MENU_WIDTH = 250;
const MENU_LABEL_MARGIN = 10;
const MENU_LABEL_PADDING_HORIZONTAL = 15;
const MENU_LABEL_PADDING_VERTICAL = 5;
const COLOR_PRIMARY = "#45d69f";
const COLOR_PINK = "#e92163";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: 'transparent',
  '&:not(:last-child)': {
    border: 0,
  },
  '&:before': {
		border: 0,
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme, ...props }) => {
  return {
    minHeight: 0,
    borderRadius: 8,
    height: 35,
    paddingLeft: MENU_LABEL_PADDING_HORIZONTAL,
    paddingRight: MENU_LABEL_PADDING_HORIZONTAL,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: MENU_LABEL_PADDING_VERTICAL,
      "&$expanded": {
        padding: MENU_LABEL_PADDING_VERTICAL,
      },
    },
  };
});

const AccordionSummary2 = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme, ...props }) => {
  return {
    minHeight: 0,
    borderRadius: 8,
    height: 40,
    paddingLeft: MENU_LABEL_PADDING_HORIZONTAL,
    paddingRight: MENU_LABEL_PADDING_HORIZONTAL,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: MENU_LABEL_PADDING_VERTICAL,
      "&$expanded": {
        padding: MENU_LABEL_PADDING_VERTICAL,
      },
    },
  };
});


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
	paddingBottom: 0,
	border: 0,
	margin: 0,
	marginTop: -14
}));

export const SideMenu = () => {
  const classes = useStylesMenu();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const user = useSelector(state => state?.user);
	const userId = user?.info?.id;
	const teamId = user?.club?.team?.id;

	const [loading, setLoading] = useState(true);
	const [menu, setMenu] = useState([]);
	const [conversationId, setConversationId] = useState("");

  useEffect(() => {  
    const fetchData = async () => {
      loadMenu();
    };

    fetchData();
  }, [userId]);

  const documentsRequestedCount = useSelector((state) => state?.documents?.requested_count);
	const invitationCount = useSelector((state) => state?.invitations?.count);
  const firstConversation = useSelector((state) => state?.conversations?.conversations?.[0]);
  const conversationCount = firstConversation ? firstConversation.count : 0;  

	const loadMenu = async() => {
		let menu = await menuList(t, user);
		setMenu(menu)
		setLoading(false)
	}

	const handleChange = (menuIndex) => {
		let newMenu = [...menu]
  	newMenu[menuIndex].open = !menu[menuIndex].open
		setMenu(newMenu)
	};

  const getTeam = async(teamId) => {
    const getTeamResp = await API.graphql({
      query: queries.getTeam,
      variables: { 
        id: teamId
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    return getTeamResp.data.getTeam;
  }

	const handleNavigate = async(path, item) => {
    let teamName = 'no_team';
    const teamId = user?.club?.team?.id;
    if(teamId) {
      const team = await getTeam(teamId);
      teamName = team?.name;
    }

    const currentPath = path.replace(':teamName', encodeURIComponent(teamName));

    const url = new URL(currentPath, window.location.origin);

    if (item.id === "calendar_and_results") {
      url.searchParams.set('team', teamName);
    }

    // En séparant le chemin et les paramètres de recherche
    const finalPath = `${url.pathname}${url.search}`;
    navigate(finalPath);
    window.scrollTo(0, 0);
	};

	const isPathActive = (currentPath, menuPath) => {
    const segmentsMenu = menuPath?.split('/');
    const segmentsCurrent = currentPath?.split('/');
    
    if (segmentsMenu?.length !== segmentsCurrent?.length) return false;

    for (let i = 0; i < segmentsMenu?.length; i++) {
        if (segmentsMenu[i] === ':teamName') continue;
        if (segmentsMenu[i] !== segmentsCurrent[i]) return false;
    }

    return true;
	}

  const renderSubMenu = (submenu) => {
    return submenu.map((subItem, submenuIndex) => {
        const isActiveSub = isPathActive(location.pathname, subItem.link_to);
        return (
            <div
                onClick={() => handleNavigate(subItem.link_to, subItem)}
                key={subItem.title}
                className={[
                    classes.subTitleZone,
                    isActiveSub ? classes.subTitleZoneFocus : ""
                ].join(" ")}
            >
                <div className={isActiveSub ? classes.menuIconFocus : classes.menuIcon}>
                    {subItem.icon}
                </div>
                <CText fontSize={15} fontWeight={500} label={subItem.title} />
            </div>
        );
    });
  }

  const renderMenu = (menuItem, menuIndex) => {
    const isActiveMenu = isPathActive(location.pathname, menuItem.link_to);
    const isActiveSubMenu = menuItem.submenu.some(sub => isPathActive(location.pathname, sub.link_to));

    if (menuItem?.submenu?.length < 1) {
        return (
            <div
              onClick={() => handleNavigate(menuItem.link_to, menuItem)}
              className={[
                classes.titleZone,
                isActiveMenu ? classes.titleZoneFocus : ""
              ].join(" ")}
            >
              <div className={isActiveMenu ? classes.menuIconFocus : classes.menuIcon}>
                {menuItem.icon}
              </div>
              <CText fontSize={15} fontWeight={500} style={{ position: 'relative' }}>
                {menuItem.title}
                {menuItem.id === "invitations" && invitationCount > 0 && (
                  <span className={classes.badgeZone}>
                    <span className={classes.badge}>
                      {invitationCount > 9 ? (
                        <span className={classes.badgeContent}>
                          <span>9</span>
                          <span className={classes.plus}>+</span>
                        </span>
                      ) : (
                        <span className={classes.number}>{invitationCount}</span>
                      )}
                    </span>
                  </span>
                )}

                {menuItem.id === "documents" && documentsRequestedCount > 0 && (
                  <span className={classes.badgeZone}>
                    <span className={classes.badge}>
                      {documentsRequestedCount > 9 ? (
                        <span className={classes.badgeContent}>
                          <span>9</span>
                          <span className={classes.plus}>+</span>
                        </span>
                      ) : (
                        <span className={classes.number}>{documentsRequestedCount}</span>
                      )}
                    </span>
                  </span>
                )}

                {menuItem.id === "messages" && conversationCount > 0 && (
                  <span className={classes.badgeZone}>
                    <span className={classes.badge}>
                      {conversationCount > 9 ? (
                        <span className={classes.badgeContent}>
                          <span>9</span>
                          <span className={classes.plus}>+</span>
                        </span>
                      ) : (
                        <span className={classes.number}>{conversationCount}</span>
                      )}
                    </span>
                  </span>
                )}
              </CText>
            </div>
        );
    } else {
        return (
          <Accordion expanded={menuItem.open} square={true} onChange={() => handleChange(menuIndex)} style={{marginLeft: 10, marginRight: 10}}>
            <AccordionSummary expandIcon={<RightChevronIcon className={classes.rightIcon} />}>
              <div className={isActiveMenu ? classes.menuIconFocus : classes.menuIcon}>
                  {menuItem.icon}
              </div>
              <CText fontSize={15} fontWeight={500} color="light" label={menuItem.title} />
            </AccordionSummary>
            <AccordionDetails>
              {renderSubMenu(menuItem.submenu)}
            </AccordionDetails>
          </Accordion>
        );
    }
  }

  return (
    <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
            paper: classes.drawerZone
        }}
    >
        <Toolbar />
        {loading ? null : (
          <div className={classes.drawerContainer}>
            <List>
              {menu?.map((menuItem, menuIndex) => (
                <React.Fragment key={menuIndex}>
                  {renderMenu(menuItem, menuIndex)}
                  <Divider className={classes.divider} />
                </React.Fragment>
              ))}
            </List>
          </div>
        )}
    </Drawer>
  );
}