import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { API, Auth } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import CButton from "../../components/Button";
import { CText } from "../../components/Text";
import CSelect from "../../components/Select";
import TextInput from '../../components/TextInput';
import { CDatePicker, CTimePicker } from '../../components/DatePicker';
import { CFormControlLabel, CFormLabel, CRadioGroup } from '../../components/Radio/Radio';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import axios from 'axios';
import { useStyles } from './calendar.styles';
import { getCurrentSeason } from "../../utils/season";
import { makeStyles } from "@material-ui/core/styles";
import { CRadio } from '../../components/Radio/Radio';
import { ReactComponent as PitchIcon } from '../../assets/icons/pitch.svg';
import { ReactComponent as TrainingIcon } from '../../assets/icons/training.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';

const CARD_WIDTH = 450;

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const WEEK_REF = [...new Array(50)].map((elmt, index) => ({ "label": (index+1).toString(), "code": index+1 }) );

const EventType = ({ event, selectedValue, handleChangeCB }) => {
  const useStyles = makeStyles((theme) => ({
    dataZone: {
      display: 'flex',
      boxSizing: 'border-box',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: ZONE_BACKGROUND_COLOR,
      border: `1px solid ${ZONE_BACKGROUND_COLOR}`,
      boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.3)',
      borderRadius: 15,
      listStyleType: 'none',
      cursor: 'pointer',
      [theme.breakpoints.up('xs')]: {
        padding: 15,
        maxWidth: CARD_WIDTH
      },
      [theme.breakpoints.up('md')]: {
        padding: 15,
        maxWidth: CARD_WIDTH,
      },
      "&&:hover": {
        filter: 'brightness(95%)',
      },
    },
    dataZoneSelected: {
      border: `1px solid ${COLOR_PRIMARY}`,
    },
    label: {
      textAlign: 'center',
      marginRight: 'auto',
    },
    icon: {
      width: 22,
      height: 22,
      marginRight: 5,
      fill: COLOR_LIGHT
    }
  }));

  const classes = useStyles();

  const handleChange = (value) => {
    handleChangeCB(value);
  };

  return (
    <li
      className={[
        classes.dataZone,
        selectedValue === event.code ? classes.dataZoneSelected : ''
      ].join(' ')}
      onClick={() => handleChange(event.code)}
    >
      <span className={classes.icon}>{event.icon}</span>
      <CText className={classes.label} level="p2" fontWeight={700} color="light">
        {event.label}
      </CText>
      <CRadio
        value={event.code}
        checked={selectedValue === event.code}
      />
    </li>
  );
};

const EventFormModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const EVENT_TYPES = [
    { "code": "match", "icon": <PitchIcon />, "label": t('calendar.labels.6.label') },
    { "code": "training", "icon": <TrainingIcon />, "label": t('calendar.labels.7.label') }
  ];

  const TRAINING_TYPES = [
    { "code": "PHYSICAL", "label": t('calendar.labels.8.label') },
    { "code": "TECHNICAL", "label": t('calendar.labels.9.label') },
    { "code": "TACTICAL", "label": t('calendar.labels.10.label') }
  ];

  const [formType, setFormType] = useState('create');
  const [loaded, setLoaded] = useState(false);
  const [week, setWeek] = useState({ "label": "1", "code": 1 });
  const [opponentName, setOpponentName] = useState("");
  const [gamePitch, setGamePitch] = useState("home");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("");
  const [hourEnd, setHourEnd] = useState("");
  const [eventType, setEventType] = useState('match');
  const [step, setStep] = useState(1);
  const [trainingType, setTrainingType] = useState({ "code": TRAINING_TYPES[0].code, "label": TRAINING_TYPES[0].label });

  useEffect(() => {
    if (props.formType === 'update') {
      setFormType('update');

      if(props.data.__typename === 'Match') {
        setEventType('match');
        setWeek({ "label": `${props.data.week}`, "code": props.data.week });
        setDate(props.data.date);
        if (props.data.home_team_name) {
          setOpponentName(props.data.home_team_name);
          setGamePitch("away");
        } else if (props.data.away_team_name) {
          setOpponentName(props.data.away_team_name);
          setGamePitch("home");
        }
        setHour(`${props.data.date}T${props.data.hour}`);
      } else if(props.data.__typename === 'Training') {
        setEventType('training');
        setTrainingType({ "code": props.data.type });
        setDate(props.data.date);
        setHour(`${props.data.date}T${props.data.start_time}`);
        setHourEnd(`${props.data.date}T${props.data.end_time}`)
      }
    } else if(props.data) {
      if(props?.data?.date) { setDate(props?.data?.date); }
      if(props?.data?.hour) { setHour(props?.data?.hour); }
    }

    setLoaded(true);
  }, [props.data, props.index]);

  const selectWeek = (data) => { setWeek(data); };
  const opponentNameCB = (name) => { setOpponentName(name); };
  const handleChangeGamePitch = (data) => { setGamePitch(data); };
  const inputDate = (data) => { setDate(data); };
  const inputHour = (data) => { setHour(data); };
  const inputHourEnd = (data) => { setHourEnd(data) };
  const selectTrainingType = (data) => { setTrainingType(data) };

  const getAuthHeaders = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();
      return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      };
    } catch (error) {
      return {};
    }
  };

  const handleSubmit = async () => {
    try {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const formatedHour = moment(hour).format("HH:mm");

      if (week.code && opponentName && gamePitch && date && hour && formatedDate !== "Invalid date" && formatedHour !== "Invalid date") {
        const currentSeason = await getCurrentSeason();
        const team = props.team;
        const competition = props.competition;
        const matchInput = {
          week: week.code,
          season: currentSeason,
          date: formatedDate,
          hour: formatedHour,
          competition_id: competition.id,
          home_team_id: gamePitch === 'home' ? team.id : null,
          home_team_name: gamePitch === 'away' ? opponentName : null,
          away_team_id: gamePitch === 'away' ? team.id : null,
          away_team_name: gamePitch === 'home' ? opponentName : null
        };

        const headers = await getAuthHeaders();

        console.log(matchInput)
        if (formType === 'create') {
          const createMatchResp = await axios.post(
            `${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${team.id}/matches`,
            matchInput,
            { headers }
          );

          const newMatch = createMatchResp.data;

          if (newMatch.home_team && newMatch.home_team.club && Array.isArray(newMatch.home_team.club.names)) {
            newMatch.home_team.club.names = newMatch.home_team.club.names.map(name => JSON.stringify(name));
          }
      
          if (newMatch.away_team && newMatch.away_team.club && Array.isArray(newMatch.away_team.club.names)) {
            newMatch.away_team.club.names = newMatch.away_team.club.names.map(name => JSON.stringify(name));
          }

          props.addEventCallback(newMatch);
        } else {
          const updateMatchResp = await axios.patch(
            `${process.env.REACT_APP_REST_API_BASE_URL}/teams/v1/teams/${team.id}/matches/${props.data.id}`,
            matchInput,
            { headers }
          );

          const updatedMatch = updateMatchResp.data;

          if (updatedMatch.home_team && updatedMatch.home_team.club && Array.isArray(updatedMatch.home_team.club.names)) {
            updatedMatch.home_team.club.names = updatedMatch.home_team.club.names.map(name => JSON.stringify(name));
          }
      
          if (updatedMatch.away_team && updatedMatch.away_team.club && Array.isArray(updatedMatch.away_team.club.names)) {
            updatedMatch.away_team.club.names = updatedMatch.away_team.club.names.map(name => JSON.stringify(name));
          }

          props.updateEventCallback(updatedMatch);
        }
        props.modalOpenCallback({ value: false });
      } else {
        let errorTitle, errorMessage;
        let errorElmts = [];

        if (!week.code || !opponentName || !gamePitch || !hour) {
          if (!week.code) errorElmts.push(t('snack_bars.0.elements.5.label'));
          if (!opponentName) errorElmts.push(t('snack_bars.0.elements.6.label'));
          if (!gamePitch) errorElmts.push(t('snack_bars.0.elements.7.label'));
          if (!hour) errorElmts.push(t('snack_bars.0.elements.9.label'));
          errorTitle = t('snack_bars.0.title');
          errorMessage = t('snack_bars.0.message');
        } else if(!date) {
          errorTitle = t('snack_bars.2.title');
          errorMessage = t('snack_bars.2.message');
        } else {
          if (formatedDate === "Invalid date") errorElmts.push(t('snack_bars.1.elements.0.label'));
          if (formatedHour === "Invalid date") errorElmts.push(t('snack_bars.1.elements.1.label'));
          errorTitle = t('snack_bars.1.title');
          errorMessage = t('snack_bars.1.message');
        }

        props.errorCallback({ errorTitle, errorMessage, errorElmts });
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };

  const handleSubmitTraining = async () => {
    try {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const formatedHour = moment(hour).format("HH:mm");
      const formatedHourEnd = moment(hourEnd).format("HH:mm");

      if (trainingType?.code && date && hour && hourEnd && formatedDate !== "Invalid date" && formatedHour !== "Invalid date" && formatedHourEnd !== "Invalid date") {
        const currentSeason = await getCurrentSeason();
        const team = props.team;

        const input = {
          type: trainingType.code,
          season: currentSeason,
          date: formatedDate,
          start_time: formatedHour,
          end_time: formatedHourEnd,
          team_id: team.id,
          teamTrainingsId: team.id
        };

        if (formType === 'create') {
          const createTrainingResp = await API.graphql({
            query: mutations.createTraining,
            variables: { input: input },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });

          const newEvent = createTrainingResp.data.createTraining;
          props.addEventCallback(newEvent);
        } else {
          input.id = props.data.id;

          const updateTrainingResp = await API.graphql({
            query: mutations.updateTraining,
            variables: { input: input },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
          const updatedMatch = updateTrainingResp.data.updateTraining;
          props.updateEventCallback(updatedMatch);
        }

        props.modalOpenCallback({ value: false });
      } else {
        let errorTitle, errorMessage;
        let errorElmts = [];

        if (!trainingType.code || !hour || !hourEnd) {
          if (!hour) errorElmts.push(t('snack_bars.0.elements.12.label'));
          if (!hourEnd) errorElmts.push(t('snack_bars.0.elements.13.label'));
          errorTitle = t('snack_bars.0.title');
          errorMessage = t('snack_bars.0.message');
        } else if(!date) {
          errorTitle = t('snack_bars.2.title');
          errorMessage = t('snack_bars.2.message');
        } else {
          if (formatedDate === "Invalid date") errorElmts.push(t('snack_bars.1.elements.0.label'));
          if (formatedHour === "Invalid date" || formatedHourEnd === "Invalid date") { errorElmts.push(t('snack_bars.1.elements.1.label')) };
          errorTitle = t('snack_bars.1.title');
          errorMessage = t('snack_bars.1.message');
        }

        props.errorCallback({ errorTitle, errorMessage, errorElmts });
      }
    } catch (err) {
      //console.log('Error:', err);
    }
  };

  const handleEventTypeChange = (type) => setEventType(type);

  const goBackToSelection = () => {
    setStep(step - 1);

    if(step -1 === 1) {
      setWeek({ "label": "1", "code": 1 });
      setOpponentName("");
      setGamePitch("home")
      setDate("");
      setHour("");
      setHour("")
      setTrainingType({ "code": TRAINING_TYPES[0].code, "label": TRAINING_TYPES[0].label })
    }
  };

  const handleNextStep = () => {
    setStep(2);
  };

  return (
    <div className={classes.modalContainer}>
      {formType === 'create' && step === 1 && (
        <div>
          <span className={classes.modalTitleContainer}>
            <CText level="h2" color="light">{t('calendar.modals.0.titles.0.label')}</CText>
          </span>
          <ul className={classes.list}>
            <Grid container spacing={1}>
              {EVENT_TYPES.map((event, index) => (
                <Grid item xs={12} key={index}>
                  <EventType
                    event={event}
                    selectedValue={eventType}
                    handleChangeCB={handleEventTypeChange}
                  />
                </Grid>
              ))}
            </Grid>
          </ul>

          <div className={classes.modalBtnZone}>
            <CButton
              className={classes.modalBtn}
              label={t('calendar.modals.0.buttons.2.label')}
              type="contained"
              color={COLOR_PRIMARY}
              height={40}
              size={14}
              onClick={handleNextStep}
            />
          </div>
        </div>
      )}

      {/* Étape 2 : Si 'match' est sélectionné, afficher le formulaire de match */}
      {((formType === 'update' && eventType === 'match' && loaded)  || (step === 2 && eventType === 'match' && loaded)) && (
        <>
          <span className={classes.modalTitleContainer}>
            {formType === 'create' ? <LeftArrowIcon className={classes.modalTitleIcon} onClick={goBackToSelection} /> : null}
            <CText level="h2" color="light">{t('calendar.modals.0.titles.1.label')}</CText>
          </span>
          <CSelect
            label={`${t('calendar.modals.0.inputs.0.label')} *`}
            data={WEEK_REF}
            selectCallback={selectWeek}
            className={classes.input}
            value={week}
          />
          <TextInput
            className={classes.input}
            label={`${t('calendar.modals.0.inputs.1.label')} *`}
            inputCallback={opponentNameCB}
            defaultValue={opponentName}
          />
          <FormControl className={`${classes.input}`}>
            <CFormLabel id="radio-buttons-group" label={`${t('calendar.modals.0.inputs.2.label')} *`} />
            <CRadioGroup
              defaultValue={gamePitch}
              onChange={handleChangeGamePitch}
            >
              <CFormControlLabel label={`${t('calendar.modals.0.labels.0.label')}`} value='home' />
              <CFormControlLabel label={`${t('calendar.modals.0.labels.1.label')}`} value='away' />
            </CRadioGroup>
          </FormControl>
          <div className={[classes.input, classes.inputInline].join(" ")}>
            <CDatePicker
              label={`${t('calendar.modals.0.inputs.3.label')} *`}
              inputCallback={inputDate}
              defaultValue={date}
            />
            <CTimePicker
              label={`${t('calendar.modals.0.inputs.4.label')} *`}
              className={classes.inputRight}
              inputCallback={inputHour}
              defaultValue={hour}
            />
          </div>
          <div className={classes.modalBtnZone}>
            <CButton
              className={classes.modalBtn}
              label={formType === 'create' ? `${t('calendar.modals.0.buttons.0.label')}` : `${t('calendar.modals.0.buttons.1.label')}`}
              type="contained"
              color="#45d69f"
              height={40}
              size={14}
              loader={true}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}

      {/* Étape 3 */}
      {((formType === 'update' && eventType === 'training' && loaded) || (step === 2 && eventType === 'training')) && (
        <>
          <span className={classes.modalTitleContainer}>
            {formType === 'create' ? <LeftArrowIcon className={classes.modalTitleIcon} onClick={goBackToSelection} /> : null}
            <CText level="h2" color="light">{t('calendar.modals.0.titles.2.label')}</CText>
          </span>

          <CSelect
            label={`${t('calendar.modals.0.inputs.5.label')} *`}
            data={TRAINING_TYPES}
            selectCallback={selectTrainingType}
            className={classes.input}
            value={trainingType}
          />

          <div className={[classes.input, classes.inputInline].join(" ")}>
            <CDatePicker
              label={`${t('calendar.modals.0.inputs.3.label')} *`}
              inputCallback={inputDate}
              defaultValue={date}
            />
          </div>
          <div className={[classes.input, classes.inputInline].join(" ")}>
            <CTimePicker
              label={`${t('calendar.modals.0.inputs.6.label')} *`}
              inputCallback={inputHour}
              defaultValue={hour}
            />
            <CTimePicker
              label={`${t('calendar.modals.0.inputs.7.label')} *`}
              className={classes.inputRight}
              inputCallback={inputHourEnd}
              defaultValue={hourEnd}
            />
          </div>
          <div className={classes.modalBtnZone}>
            <CButton
              className={classes.modalBtn}
              label={formType === 'create' ? `${t('calendar.modals.0.buttons.0.label')}` : `${t('calendar.modals.0.buttons.1.label')}`}
              type="contained"
              color="#45d69f"
              height={40}
              size={14}
              loader={true}
              onClick={handleSubmitTraining}
            />
          </div>
        </>
      )}
    </div>
  );
}
  
export default EventFormModal;