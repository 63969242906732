import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useStyles } from './Messages.styles.js';
import MessageInput from '../../components/Input/MessageInput.js';
import axios from 'axios';
import { Auth, API } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import CButton from "../../components/Button.js";
import { updateTeamNameInPath } from "../../utils/urlRedirections";
import { resetConversationCount, addMessageToConversation, updateMessageIdInConversation } from '../../store/reducers/conversations';
import Lottie from 'lottie-react';
import * as animationData from '../../assets/images/messages-animation.json';
import ProfilePicture from "../../components/ProfilePicture/index";
import Tooltip from '@mui/material/Tooltip';
import { formatMessageDate } from "../../utils/dates";
import NoTeamsCard from "../../components/Cards/NoTeamsCard";
import { getUserGroups } from "../../utils/auth";

const skeletons = [
  { width: '55%', height: 80, alignment: 'right' },
  { width: '60%', height: 60, alignment: 'right' },
  { width: '50%', height: 50, alignment: 'left' },
  { width: '50%', height: 50, alignment: 'left' },
  { width: '60%', height: 70, alignment: 'left' }
];

const getAuthHeaders = async () => {
  try {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    return {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    };
  } catch (error) {
    return {};
  }
};

// Fonction pour marquer les messages comme lus
const markMessageAsRead = async (teamId, conversationId, messageId) => {
  try {
    const headers = await getAuthHeaders();
    await axios.patch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations/${conversationId}/messages/${messageId}`,
      { read: true },
      { headers: headers }
    );
  } catch (error) {
    console.error('Erreur lors de la mise à jour du statut de lecture du message:', error);
  }
};

const Messages = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const userStored = useSelector((state) => state?.user);
  const userId = userStored?.info?.id;
  const teamId = userStored?.club?.team?.id;

  const [input, setInput] = useState('');
  const [messageQueue, setMessageQueue] = useState([]);

  const isAuthenticated = useSelector((state) => state?.user.isAuthenticated);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [messages, setMessages] = useState([]);
  const [sending, setSending] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [noConversation, setNoConversation] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  // Références
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  const localMessages = useSelector((state) =>
    state.conversations.conversations.find(convo => convo.id === conversationId)?.messages || []
  );

  useEffect(() => {
    if (localMessages && JSON.stringify(messages) !== JSON.stringify(localMessages)) {
      dispatch(resetConversationCount({ id: conversationId }));
      setMessages(localMessages);

      // Handle read message
      if(localMessages?.length > 0) {
        const lastMessage = localMessages.length > 0 ? localMessages[0] : null;
        if(!lastMessage?.read_by?.includes(userId)) {
          markMessageAsRead(teamId, conversationId, lastMessage?.id);
        } 
      }
    }
  }, [localMessages]);

  const fetchConversation = async () => {
    try {
      const headers = await getAuthHeaders();

      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations`,
        { headers }
      );

      if (response.data.length === 0) {
        setNoConversation(true);
        setLoading(false);
        return false;
      }

      setConversation(response.data[0]);
      setConversationId(response.data[0].id);

      return true;
    } catch (error) {
      //console.error('Erreur lors de la récupération des détails de la conversation:', error.response ? error.response.data : error.message);
      setNoConversation(true);
      setLoading(false);
      return false;
    }
  };

  const fetchMessages = async (key = null) => {
    try {
      if (key) setLoadingMore(true);
      else setLoading(true);

      const headers = await getAuthHeaders();
      const params = key ? `&last_evaluated_key=${encodeURIComponent(JSON.stringify(key))}` : '';

      const container = containerRef.current;

      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations/${conversationId}/messages?sort=asc&limit=13${params}`,
        { headers }
      );

      const newMessages = response.data.messages.filter(msg => msg && msg.content);

      if (key) {
        newMessages.reverse().forEach((msg) => {
          dispatch(addMessageToConversation({ id: conversationId, message: msg, prepend: false }));
        });

      } else {
        newMessages.forEach((msg) => {
          dispatch(addMessageToConversation({ id: conversationId, message: msg, prepend: true }));
        });

        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }

      setLastEvaluatedKey(response.data.last_evaluated_key ? JSON.parse(decodeURIComponent(response.data.last_evaluated_key)) : null);
    } catch (error) {
      //console.error('Erreur lors de la récupération des messages:', error.response ? error.response.data : error.message);
    } finally {
      setLoadingMore(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated && teamId) {
        await fetchConversation();
      }
    };
  
    fetchData();
  }, [isAuthenticated, teamId, conversationId]);

  useEffect(() => {
    const fetchData = async () => {
      if (conversationId) {
        await fetchMessages();
      }
    };
  
    fetchData();
  }, [conversationId]);

  useEffect(() => {
    const fetchData = async () => {
      if (conversationId) {
        //console.log(teamId, conversationId, messages[0]?.id)
        //console.log(messages)
        //
      }
    };
  
    fetchData();
  }, [conversationId, messages]);

  const handleScroll = () => {
    const container = containerRef.current;
    const scrollBottom = container.scrollHeight - (-container.scrollTop + container.clientHeight);
    
    if (
      scrollBottom <= 1 &&
      !loading &&
      lastEvaluatedKey
    ) {
      fetchMessages(lastEvaluatedKey);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, lastEvaluatedKey]);

    useEffect(() => {
    const sendMessages = async () => {
      if (sending || messageQueue.length === 0) return;
      setSending(true);
  
      const nextMessage = messageQueue[0];
      const headers = await getAuthHeaders();
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations/${conversationId}/messages`,
          { content: nextMessage.content, type: nextMessage.type },
          { headers }
        );
  
        dispatch(
          updateMessageIdInConversation({
            id: conversationId,
            tempId: nextMessage.id,
            newId: response.data.id
          })
        );
  
        setMessageQueue(prevQueue => prevQueue.slice(1));
      } catch (error) {
        console.error("Erreur lors de l'envoi du message:", error);
        setMessageQueue(prevQueue => prevQueue.slice(1));
      } finally {
        setSending(false);
      }
    };
  
    sendMessages();
  }, [messageQueue, sending, conversationId, teamId]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSend = async () => {
    if (input.trim()) {
      const tempId = Date.now();
      const newMessage = {
        content: input,
        type: "TEXT",
        id: tempId,
        sender: { id: userId },
        created_at: new Date().toISOString()
      };

      setMessageQueue(prevQueue => [...prevQueue, newMessage]);
      dispatch(addMessageToConversation({ id: conversationId, message: newMessage, prepend: true }));
      setInput('');

      if (containerRef.current) {
        setTimeout(() => {
          containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }, 10);
      }
    }
  };

  const createConversation = async () => {
    if (!conversationId) {
      const headers = await getAuthHeaders();
      const createResponse = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/conversations/v1/teams/${teamId}/conversations`,
        {},
        { headers }
      );

      setConversationId(createResponse.data.id);
      setNoConversation(false);
    }
  }

  let CONTENT;

  if (loading) {
    CONTENT = (
      <div className={classes.skeletonWrapper}>
        {skeletons.map((skeleton, index) => (
          <div
            key={index}
            style={{
              width: skeleton.width,
              height: skeleton.height,
              alignSelf: skeleton.alignment === 'right' ? 'flex-end' : 'flex-start',
            }}
          >
            <Skeleton variant="rounded" width={'100%'} height={'100%'} />
          </div>
        ))}
      </div>
    );
  } else if (!teamId) {
    CONTENT = (
      <div className={classes.noTeamsInfo}>
        <NoTeamsCard redirectBtn={userGroups.includes("ClubAdmins")} />
      </div>
    );
  } else if (noConversation) {
    CONTENT = (
      <div className={classes.noConversation}>
        <div style={{ maxWidth: 400, maxHeight: 400 }}>
          <Lottie animationData={animationData} loop={true} autoplay={true} />
        </div>
        <CButton
          className={classes.noConversationBtn}
          label={t('no_conversation.buttons.0.label')}
          height={38}
          type="contained"
          loader={true}
          onClick={async () => await createConversation()}
        />
      </div>
    );
  } else {
    CONTENT = (
      <>
        {loadingMore && <CircularProgress size={25} className={classes.spinner} />}
        {messages.map((msg, msgIndex) => (
          <div className={classes.message} key={msgIndex}>
            {msg.sender && msg.sender.id === userId ? null : (
              <Tooltip title={`${msg?.sender?.first_name} ${msg?.sender?.last_name}`}>
                <div>
                  <ProfilePicture className={classes.userPhoto} src={msg?.sender?.photo_path} />
                </div>
              </Tooltip>
            )}
            <Tooltip title={`${formatMessageDate(msg?.created_at, i18n.language)}`}>
              <div
                key={msg.id}
                className={`${classes.messageBubble} ${
                  msg.sender && msg.sender.id === userId ? classes.myMessage : classes.otherMessage
                }`}
              >
                <span className={classes.messageText}>{msg.content}</span>
              </div>
            </Tooltip>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.messagesContainer} ref={containerRef}>
        {CONTENT}
      </div>
      {conversation && (
        <MessageInput
          value={input}
          onChange={handleInputChange}
          onSend={handleSend}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Messages;